import { useEffect } from 'react';
import GeneralData from './../../generalData';
import Layout from '../layoutComponents/layout';
import Router from '../../router';
import Team from '../models/Team';
import TeamsData from '../../../data/miembros';
import InitPageHelper from '../helpers/PageInitHelper';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const MIEMBROS_ACTIVOS = TeamsData.filter(function (miembro) {
    return (
      (miembro.type === 'integrante' || miembro.type === 'coordinador') &&
      miembro.status == 'active'
    );
  });

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Sobre el GrIS', route: Router.about },
        { name: 'Integrantes', route: '' },
      ]}
    >
      <section className={`${GeneralData.style.pages.firstSectionContainer}`}>
        <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
          <div className="flex flex-col items-center sm:-mx-3 gap-12">
            <h1 className={` ${GeneralData.style.pages.firstSectionTitle}`}>
              <span className="block xl:inline">Integrantes del</span>
              {` `}
              <span className="block text-primary-700 xl:inline"> GrIS</span>
            </h1>
            <p
              className={`text-lg text-center ${GeneralData.style.layout.text.grey} w-full lg:w-3/4`}
            >
              Integrantes activos.
            </p>
          </div>
        </div>
      </section>
      {MIEMBROS_ACTIVOS.length > 0 && (
        <section className="px-2 py-8  lg:py-18 bg-primary-50 md:px-0">
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div
              className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
            >
              Integrantes
            </div>
            <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {MIEMBROS_ACTIVOS.map((data) => (
                <div className="w-full">
                  <Team key={data.name} team={data} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
}

export default App;
