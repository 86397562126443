const data = [
  {
    title: 'IS.uy',
    id: 'IS.uy',
    shortDescription:
      'IS.uy es un programa para impulsar y desarrollar aún más la Ingeniería de Software en Uruguay. Su propósito es generar sinergía, fomentar y fortalecer los vínculos entre la industria , el estado y la academia mediante una intensa colaboración conjunta.',
    longDescription: '',
    img: ['https://is.uy/wp-content/uploads/2019/07/header-home.png?x27293'],
    urlMoreInformation: 'https://is.uy/',
  },
  {
    title: 'JIS.uy',
    id: 'JIS.uy',
    shortDescription: 'Jornadas de Ingeniería de Software',
    longDescription: '',
    img: ['https://is.uy/wp-content/uploads/2019/08/jislogoNew1.png?x27293'],
    urlMoreInformation: 'https://is.uy/jis-uy-2021/',
  },
  {
    title: 'MIS.uy',
    id: 'MIS.uy',
    shortDescription: 'Meetups de Ingeniería de Software',
    longDescription: '',
    img: [
      'https://is.uy/wp-content/uploads/2019/08/header-home-MIS.png?x27293',
    ],
    urlMoreInformation: 'https://is.uy/mis-uy/',
  },
  {
    title: 'VIS.uy',
    id: 'VIS.uy',
    shortDescription: 'Videos de  Ingeniería de Software',
    longDescription:
      'Los videos de ingeniería de software (VIS.uy) son charlas grabadas que buscan presentar temas de interés en ingeniería de software con el objetivo de acercar a los profesionales de la industria y la academia, fomentando el intercambio y colaboración en diferentes áreas. Los videos buscan tratar problemas de actualidad y desafíos a los cuales se enfrenta la industria de software hoy en día.',
    img: ['https://is.uy/wp-content/uploads/2022/04/vis4.png?x27293'],
    urlMoreInformation: 'https://is.uy/vis-uy/',
  },
];

export default data;
