import { useEffect } from 'react';
import Layout from '../layoutComponents/layout';
import Router from '../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import Data from './../../../data/propuestaDeTesisYTutorias';
import Tesis from '../../components/models/TesisTutorias';

import { FaHandPointDown, FaFrown } from 'react-icons/fa';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const DATOS = Data.filter(function (item) {
    return item.status === 'open';
  });

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Enseñanza', route: Router.ensenanza },
        { name: 'Propuestas de tesis', route: '' },
      ]}
    >
      <>
        <section className={`${GeneralData.style.pages.firstSectionContainer}`}>
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="flex flex-wrap items-center sm:-mx-3">
              <h1 className={` ${GeneralData.style.pages.firstSectionTitle}`}>
                <span className="block xl:inline">Propuestas de </span>
                {` `}
                <span className="block text-primary-700 xl:inline">tesis</span>
              </h1>
            </div>
          </div>
        </section>

        {DATOS.length > 0 ? (
          <section className="px-2 py-8  lg:py-18 bg-primary-50 md:px-0">
            <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
              <div
                className={`text-base  w-full text-center  mb-6 ${GeneralData.style.layout.text.darkLight}`}
              >
                Listado de propuestas abiertas{` `}
                <FaHandPointDown className="inline" />
              </div>
              <div className="w-full grid grid-cols-1 gap-4 ">
                {DATOS.map((data) => (
                  <div className="w-full">
                    <Tesis key={data.title} data={data} />
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          <section className="px-2 py-8  lg:py-18 bg-white md:px-0">
            <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
              <div
                className={`text-3xl  w-full text-center  mb-6 ${GeneralData.style.layout.text.greyLight}`}
              >
                No hay propuestas abiertas en este momento {` `}
                <FaFrown className="inline" />
              </div>
            </div>
          </section>
        )}
      </>
    </Layout>
  );
}

export default App;
