const data = [
  {
    title:
      'Convenio con la Agencia para el Desarrollo del Gobierno de Gestión Electrónica y la Sociedad de la Información y el Conocimiento (Agesic) para apoyar en la mejora e implantación del Modelo de Calidad.',
    shortDescription: `Apoyo en la evaluación, mejora e implantación del MCS, así como también en la generación de planes de formación académica para competencias en TI (que forman parte del MCS).`,
    place: '',
    clientName: 'Agesic',
    participants: ['Diego Vallespir', 'Cecilia Apa', 'Jorge Triñanes'],
    dateStart: '2019-05-31', // respetar formato de fecha
    dateEnd: '2020-11-14', // respetar formato de fecha
    img: ['/images/asesorias/agesic.png'],
    urlMoreInformation: '',
  },

  {
    title:
      'Convenio con el Servicio Central de Informática de la Universidad de la República (SeCIU) para apoyo al desarrollo del nuevo sistema de Bedelías.',
    shortDescription: `Brindar asesoramiento y apoyo al SeCIU en lo que respecta a técnicas, métodos, y procesos de la ingeniería de software en el marco del desarrollo del nuevo sistema de software para la gestión de Bedelías.`,
    place: '',
    clientName: 'SeCIU',
    participants: [
      'Diego Vallespir',
      'Cecilia Apa',
      'María de las Nieves Freira',
    ],
    dateStart: '2008-06-31', // respetar formato de fecha
    dateEnd: '2009-07-14', // respetar formato de fecha
    img: ['/images/asesorias/SeCIU.png'],
    urlMoreInformation: '',
  },
];

export default data;
