import React, { useState } from 'react';
import GeneralData from './../../../generalData';
import LogoCepap from './../../../../img/logo-cpap.png';
import useWindowSize from '../../composables/use-window-size';
import TituloSeccionComponent from '../cursos/TituloSeccionComponent';
import TextWithShowMore from '../../helpers/TextWithShowMore';
import LineSeparator from '../../helpers/LineSeparator';
import TeamBoxForCarrerAndCourses from '../../layoutComponents/team/TeamBoxForCarrerAndCourses';
import {
  FaCheckDouble,
  FaGraduationCap,
  FaHourglassEnd,
  FaLaptopHouse,
  FaRegCalendar,
  FaRegClock,
  FaTag,
} from 'react-icons/fa';
import SectionBlock from '../cursos/SectionBlock';
import ContactSection from '../cursos/ContactSection';
import { Link } from 'react-router-dom';
import router from '../../../router';
import useMember from '../../composables/use-member';
import useCarrera from '../../composables/use-carrera';
import carreras from '../../../../data/carreras';

function CarreraPageComponent(props) {
  const { width } = useWindowSize();

  const {
    name,
    longDescription,
    requirementsToEnter,
    modalityOfTheCourse,
    graduateProfile,
    graduateProfileItems,
    imgPathSm: imgPath,
    imgPathLg,
    scholarshipsAndDiscounts,
    teachers,
    schedules,
    graduationTitleName,
    durationOfCareer,
    registrationAndStart,
    coursesIds,
  } = props;

  const [showMoreInfoGraduateProfile, setShowMoreInfoGraduateProfile] =
    useState(false);

  const { getDynamicTeacherTeamsNoResponsableNoCoordinator } = useCarrera();

  const {
    getTeacherOfCarreaOrCoursesNoCoordinatorNoResponsable,
    getTeacherOfCarreraOrCourseCoordinator,
    getTeacherOfCarreraOrCourseResponsable,
  } = useMember();

  const teacherResponsable = getTeacherOfCarreraOrCourseResponsable(teachers);

  const teacherCoordinator = getTeacherOfCarreraOrCourseCoordinator(teachers);

  const namesResponsableAndCoordinator = [];
  teacherResponsable.forEach((teacher) => {
    namesResponsableAndCoordinator.push(teacher.name);
  });

  teacherCoordinator.forEach((teacher) => {
    namesResponsableAndCoordinator.push(teacher.name);
  });

  const teachersDynamicFromCourses =
    getDynamicTeacherTeamsNoResponsableNoCoordinator(
      coursesIds,
      namesResponsableAndCoordinator
    );

  const teachersTeam = getTeacherOfCarreaOrCoursesNoCoordinatorNoResponsable(
    teachersDynamicFromCourses
  );

  return (
    <div className="w-full flex flex-col gap-8">
      <SectionBlock>
        <div className="flex flex-col lg:flex-row lg:h-[350px]  overflow-hidden border-b border-solid border-primary-100">
          <div className="order-2 lg:order-1  lg:w-1/2 p-6 lg:p-12 flex flex-col items-start justify-center gap-8 lg:gap-0">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <div
                  className={`flex items-center gap-1 rounded text-base text-primary-400 p-1 font-bold bg-primary-50`}
                >
                  <FaGraduationCap className="inline" /> Carrera de posgrado
                </div>
              </div>
              <h1
                className={`text-4xl font-bold ${GeneralData.style.layout.text.dark}`}
              >
                {name}
              </h1>
            </div>
          </div>
          <div className="order-1 lg:order-2 lg:h-full lg:w-1/2 ">
            <div className="overflow-hidden relative h-full">
              <img
                src={`${GeneralData.aplicationPublicPath}${
                  width > 600 ? imgPathLg : imgPath
                }`}
                alt=""
                className="object-cover w-full h-full"
              />
              <div className="absolute  overflow-hidden inline-block bottom-0 right-0  w-full h-full">
                <div className="absolute top-0 right-0  w-1/3 z-10">
                  <img src={LogoCepap} alt="" />
                </div>
                <div className="w-full h-full bg-primary-700 bg-opacity-70 clip-triangle-rectangle"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row">
          <div className="p-6 lg:p-12  w-full">
            {longDescription && (
              <TituloSeccionComponent
                titulo="Información general de la carrera"
                seccion={
                  <TextWithShowMore
                    text={longDescription}
                    showMoreIfLengthMayorThan={800}
                  ></TextWithShowMore>
                }
              />
            )}
          </div>
        </div>
      </SectionBlock>

      <SectionBlock>
        <div className="p-6 lg:p-12  w-full">
          {graduateProfile && (
            <>
              <div className="flex flex-col gap-4">
                <TituloSeccionComponent
                  titulo="Perfil del Egresado"
                  seccion={
                    <TextWithShowMore
                      text={graduateProfile}
                      showMoreIfLengthMayorThan={500}
                    ></TextWithShowMore>
                  }
                />

                {graduateProfileItems && graduateProfileItems.length > 0 && (
                  <div className="flex flex-col gap-4">
                    {showMoreInfoGraduateProfile && (
                      <div className="text-sm w-full flex flex-col gap-2">
                        <div className="font-bold">
                          Capacidades que el egresado adquirirá
                        </div>
                        <ul className="mt-2  marker:text-primary-300 list-disc list-none grid gap-4 grid-cols-2 lg:grid-cols-4">
                          {graduateProfileItems.map((gradueProfileItem) => (
                            <li
                              key={`gradueProfileItem-${gradueProfileItem}`}
                              className="bg-primary-50 shadow-sm rounded-lg p-3 hover:bg-primary-100 transition-all duration-300 relative"
                            >
                              <div className="">
                                <p className="text-sm ">
                                  {gradueProfileItem.slice(0, 400)}
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div
                      onClick={() =>
                        setShowMoreInfoGraduateProfile(
                          !showMoreInfoGraduateProfile
                        )
                      }
                      className={`${GeneralData.style.layout.text.link}} text-sm cursor-pointer`}
                    >
                      {' '}
                      {!showMoreInfoGraduateProfile
                        ? 'Capacidades que el egresado adquirirá'
                        : 'Ocultar'}{' '}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </SectionBlock>

      <div className="mx-auto container max-w-6xl  flex flex-col gap-8 lg:flex-row grid grid-cols-1 lg:grid-cols-2">
        <div className="flex flex-col gap-8">
          <SectionBlock>
            <div className="p-6 lg:p-8  w-full bg-primary-100 flex flex-col items-center">
              <Link to={router.planGet(name)}>
                <div className="relative w-full  bg-white rounded p-6 md:p-8 lg:px-16 shadow hover:shadow-xl border border-solid border-primary-50 overflow-hidden rounded-lg w-full  flex flex-col items-end lg:flex-row lg:items-center justify-center lg:justify-end ">
                  <div className=" flex flex-col gap-8 z-20 items-center">
                    <div className="text-5xl">
                      <FaGraduationCap
                        className={`inline ${GeneralData.style.layout.text.primary}`}
                      />
                    </div>

                    <div className="text-center flex flex-col items-center gap-2 text-primary-800">
                      <div className="text-2xl font-bold">Plan de estudios</div>
                      <div>Cursos y cronograma</div>
                    </div>

                    <Link
                      to={router.planGet(name)}
                      className={` ${GeneralData.style.layout.button(
                        'primary'
                      )} `}
                    >
                      Explorar
                    </Link>
                  </div>
                </div>
              </Link>
            </div>
          </SectionBlock>

          <SectionBlock>
            <div className="p-6 lg:p-12  w-full">
              {teachers && (
                <>
                  <TituloSeccionComponent
                    titulo="Responsable"
                    seccion={
                      <div className="flex flex-col gap-4">
                        <div className=" gap-3 grid grid-cols-1 md:grid-cols-2 mt-2">
                          <TeamBoxForCarrerAndCourses
                            teacersObjects={teacherResponsable}
                          />
                        </div>
                      </div>
                    }
                  ></TituloSeccionComponent>
                  <LineSeparator />
                  <TituloSeccionComponent
                    titulo="Coordinador"
                    seccion={
                      <div className="flex flex-col gap-4">
                        <div className=" gap-3 grid grid-cols-1 md:grid-cols-2 mt-2">
                          <TeamBoxForCarrerAndCourses
                            teacersObjects={teacherCoordinator}
                          />
                        </div>
                      </div>
                    }
                  ></TituloSeccionComponent>

                  <LineSeparator />
                  <TituloSeccionComponent
                    titulo="Equipo docente"
                    seccion={
                      <div className="flex flex-col gap-4">
                        <div className="py-3 gap-3 grid grid-cols-1 md:grid-cols-2  max-h-[350px] overflow-y-scroll scrollbar-thin scrollbar-thumb-primary-100 scrollbar-track-primary-50">
                          <TeamBoxForCarrerAndCourses
                            teacersObjects={teachersTeam}
                          />
                        </div>
                      </div>
                    }
                  ></TituloSeccionComponent>
                </>
              )}
            </div>
          </SectionBlock>
        </div>
        <div className="flex flex-col gap-8">
          <SectionBlock>
            <div className="p-6 lg:p-12 flex flex-col gap-8 w-full">
              {graduationTitleName && (
                <>
                  <TituloSeccionComponent
                    graduación
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaGraduationCap
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Título de graduación
                      </div>
                    }
                    seccion={graduationTitleName}
                  />
                </>
              )}

              {durationOfCareer && (
                <>
                  <TituloSeccionComponent
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaHourglassEnd
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Duración de la carrera
                      </div>
                    }
                    seccion={durationOfCareer}
                  />
                </>
              )}

              {requirementsToEnter && (
                <>
                  <TituloSeccionComponent
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaCheckDouble
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Requisitos de ingreso
                      </div>
                    }
                    seccion={
                      <TextWithShowMore
                        text={requirementsToEnter}
                        showMoreIfLengthMayorThan={500}
                      ></TextWithShowMore>
                    }
                  />
                </>
              )}

              {registrationAndStart && (
                <>
                  <TituloSeccionComponent
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaRegCalendar
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Inscripciones e inicio
                      </div>
                    }
                    seccion={
                      <TextWithShowMore
                        text={registrationAndStart}
                        showMoreIfLengthMayorThan={500}
                      ></TextWithShowMore>
                    }
                  />
                </>
              )}

              {schedules && (
                <>
                  <TituloSeccionComponent
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaRegClock
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Horarios
                      </div>
                    }
                    seccion={
                      <TextWithShowMore
                        text={schedules}
                        showMoreIfLengthMayorThan={500}
                      ></TextWithShowMore>
                    }
                  />
                </>
              )}

              {modalityOfTheCourse && (
                <>
                  <TituloSeccionComponent
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaLaptopHouse
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Modalidad de cursado
                      </div>
                    }
                    seccion={
                      <TextWithShowMore
                        text={modalityOfTheCourse}
                        showMoreIfLengthMayorThan={500}
                      ></TextWithShowMore>
                    }
                  />
                </>
              )}

              {scholarshipsAndDiscounts && (
                <>
                  <TituloSeccionComponent
                    titulo={
                      <div className="flex items-center gap-2">
                        {' '}
                        <FaTag
                          className={`inline ${GeneralData.style.layout.text.primary}`}
                        />{' '}
                        Becas y descuentos
                      </div>
                    }
                    seccion={
                      <TextWithShowMore
                        text={scholarshipsAndDiscounts}
                        showMoreIfLengthMayorThan={500}
                      ></TextWithShowMore>
                    }
                  />
                </>
              )}
            </div>
          </SectionBlock>
          <SectionBlock>
            <div className="p-6 lg:p-12  w-full">
              <>
                <TituloSeccionComponent
                  titulo="Contacto"
                  seccion={<ContactSection />}
                />
              </>
            </div>
          </SectionBlock>
        </div>
      </div>
    </div>
  );
}

export default CarreraPageComponent;
