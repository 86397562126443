import React, { useState } from 'react';
import GeneralData from '../../generalData';
import TeamBox from '../layoutComponents/team/TeamBox';
import useMember from '../composables/use-member';

function Publication(props) {
  const [open, setOpen] = useState(false);

  const publicationData = props.data.info;

  const autoresTexto = publicationData.authors.author
    .map(function (person) {
      return person.text;
    })
    .join(', ');

  const { getMembersOfPublication } = useMember();

  const Techers = getMembersOfPublication(publicationData);

  function showMore() {
    setOpen(!open);
  }

  return (
    <li className="mb-10 ml-4 bg-white shadow  rounded-xl p-2">
      <div className=" absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
      <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
        {publicationData.year}
      </time>
      {Techers.length > 0 && <TeamBox teachers={Techers} />}
      <p className="cursor-pointer" onClick={showMore}>
        {autoresTexto}
      </p>
      <h3 className={`text-lg font-bold ${GeneralData.style.layout.text.dark}`}>
        {publicationData.title}
      </h3>
      <div></div>
      <p
        className={`text-base font-normal ${GeneralData.style.layout.text.darkLight} mb-2`}
      >
        {publicationData.type} | <strong>{publicationData.venue}</strong>
      </p>
      <a
        href={publicationData.url}
        className={`inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 ${GeneralData.style.layout.text.primaryHover} focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700  `}
      >
        Ver publicación{' '}
        <svg
          className="ml-2 w-3 h-3"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </a>
    </li>
  );
}

export default Publication;
