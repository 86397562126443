import { useEffect } from 'react';
import Layout from '../layoutComponents/layout';
import Router from '../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import Data from './../../../data/propuestaDeTesisYTutorias';
import Tutoria from '../../components/models/TesisTutorias';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const DATOS = Data.filter(function (item) {
    return item.status === 'finalized' || item.status === 'in progress';
  });

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Enseñanza', route: Router.ensenanza },
        { name: 'Tutorías realizadas o en curso', route: '' },
      ]}
    >
      <>
        <FirstSection
          titleContent={
            <>
              <span className="block xl:inline">Tesis/tutorías</span>
            </>
          }
          descriptionContent={<p>(Realizadas o en curso)</p>}
        />

        {DATOS.length > 0 && (
          <BasicSection smallPadding bgHighlighted fullWidth>
            <div className="w-full grid grid-cols-1 gap-4 ">
              {DATOS.map((data) => (
                <div className="w-full">
                  <Tutoria key={data.title} data={data} />
                </div>
              ))}
            </div>
          </BasicSection>
        )}
      </>
    </Layout>
  );
}

export default App;
