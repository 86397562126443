import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './../layoutComponents/layout';
import Router from './../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import PlanPageComponent from './carreras/PlanPageComponent';
import useCarrera from '../composables/use-carrera';

function Carrera() {
  const { carreraByName } = useCarrera();

  let name = useParams().name.toLowerCase().replaceAll('-', ' ');

  const carrera = carreraByName(name);

  useEffect(() => {
    window.scrollTo(0, 0);
    InitPageHelper.PageInitHelper({
      title: carrera.name,
      description: carrera.shortDescription,
    });
  }, [carrera.name]);

  return (
    <Layout
      customClasses="bg-gradient-to-b from-white to-primary-50  lg:py-8 p-3"
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Enseñanza', route: Router.ensenanza },
        { name: 'Carreras', route: Router.carreras },
        {
          name: name.slice(0, 16) + '...',
          route: Router.carreraGet(carrera.name),
        },
        { name: 'Plan de estudio', route: '' },
      ]}
    >
      <>
        <div className="w-full ">
          <PlanPageComponent {...carrera} />
        </div>
      </>
    </Layout>
  );
}

export default Carrera;
