import GeneralData from '../../../generalData';
import { Link } from 'react-router-dom';
import Router from '../../../router';

const TeamBox = (props) => {
  const { teachers } = props;

  return (
    <div className="flex flex-row items-center gap-2">
      {teachers.map((team) => (
        <Link key={`tea-box-${team.name}`} to={Router.integranteGet(team.name)}>
          <img
            src={
              team.img.length > 0
                ? process.env.PUBLIC_URL + team.img[0]
                : GeneralData.generalUserImg
            }
            alt=""
            className="flex-none w-6 h-6 rounded-full object-cover"
            loading="lazy"
          />
        </Link>
      ))}
    </div>
  );
};

export default TeamBox;
