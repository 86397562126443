import { useEffect } from 'react';
import GeneralData from '../../generalData';
import Layout from '../layoutComponents/layout';
import Team from '../models/Team';
import InitPageHelper from '../helpers/PageInitHelper';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';
import useMember from '../composables/use-member';

const {
  getGroupActiveMembers,
  getGroupInactiveMembers,
  getGroupCollaboratorMembers,
} = useMember();

const MIEMBROS_ACTIVOS = getGroupActiveMembers();
const MIEMBROS_INACTIVOS = getGroupInactiveMembers();
const COLABORADORES = getGroupCollaboratorMembers();

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  InitPageHelper.PageInitHelper(props.pageData);

  return (
    <Layout>
      <FirstSection
        titleContent={
          <>
            {' '}
            <span className="block xl:inline">Sobre el</span>
            {` `}
            <span className="block text-sky-700 xl:inline"> GrIS</span>
          </>
        }
        descriptionContent={
          <>
            <p>
              Grupo que realiza investigación en temas relativos a la ingeniería
              de software. Principalmente enfocados en procesos de desarrollo de
              software y su mejora, calidad de software, evaluación de técnicas
              de ingeniería de software, ingeniería de software basada en
              evidencia y educación en ingeniería de software.
            </p>

            <p>
              El GrIS también colabora con la Industria de Software nacional
              asesorando sobre procesos de desarrollo y mantenimiento de
              software, mejora de dichos procesos, gestión de servicios de TI,
              mejora de técnicas de desarrollo de software y verificación,
              procesos de pruebas de software, entre otros.
            </p>
          </>
        }
      />

      {MIEMBROS_ACTIVOS.length > 0 && (
        <BasicSection smallPadding fullWidth bgHighlighted>
          <div>
            <div
              className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
            >
              Integrantes
            </div>
            <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {MIEMBROS_ACTIVOS.map((data) => (
                <div className="w-full">
                  <Team key={data.name} team={data} />
                </div>
              ))}
            </div>
          </div>
        </BasicSection>
      )}

      {COLABORADORES.length > 0 && (
        <BasicSection smallPadding fullWidth bgHighlighted>
          <div>
            <div
              className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
            >
              Colaboradores
            </div>
            <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {COLABORADORES.map((data) => (
                <div className="w-full">
                  <Team key={data.name} team={data} />
                </div>
              ))}
            </div>
          </div>
        </BasicSection>
      )}

      {MIEMBROS_INACTIVOS.length > 0 && (
        <BasicSection smallPadding fullWidth bgHighlighted>
          <div>
            <div
              className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
            >
              Anteriores
            </div>
            <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {MIEMBROS_INACTIVOS.map((data) => (
                <div className="w-full opacity-70">
                  <Team key={data.name} team={data} />
                </div>
              ))}
            </div>
          </div>
        </BasicSection>
      )}
    </Layout>
  );
}

export default App;
