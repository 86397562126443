import { useEffect } from 'react';

import Layout from './../layoutComponents/layout';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import Router from './../../router';
import useCarrera from '../composables/use-carrera';
import Carrera from '../models/Carrera';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const { carreras } = useCarrera();

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Enseñanza', route: Router.ensenanza },
        { name: 'Carreras del GrIS', route: '' },
      ]}
    >
      <>
        <FirstSection
          titleContent={
            <>
              <span className="block xl:inline">Carreras de </span>
              {` `}
              <span className="block text-primary-500 xl:inline">posgrado</span>
            </>
          }
          descriptionContent={
            <p>Carreras de posgrado que coordinamos y o colaboramos.</p>
          }
        />

        <BasicSection smallPadding fullWidth bgHighlighted>
          <div>
            <div className="w-full flex items-center justify-center flex-wrap gap-8">
              {carreras.map(function (carrera) {
                return (
                  <div className="w-[350px]">
                    <Carrera {...carrera} />
                  </div>
                );
              })}
            </div>
          </div>
        </BasicSection>
      </>
    </Layout>
  );
}

export default App;
