import { useEffect, useState } from 'react';
import Layout from './../layoutComponents/layout';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import actividades from '../../../data/actividades';
import seminarios from '../../../data/seminarios';
import asesorias from '../../../data/conveniosYAsesorias';
import Convenio from './../models/Convenio';
import SeminarioCharla from './../models/SeminarioCharla';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);
  const ACTIVIDADES = actividades;

  const SEMINARIOS = seminarios.sort(function (a, b) {
    return new Date(b.dateStart) - new Date(a.dateStart);
  });

  const CONVENIOS = asesorias.sort(function (a, b) {
    return new Date(b.dateStart) - new Date(a.dateStart);
  });

  const [
    cantidadDeSeminariosQueSeMuestran,
    setCantidadDeSeminariosQueSeMuestran,
  ] = useState(3);

  function loadMore() {
    setCantidadDeSeminariosQueSeMuestran(cantidadDeSeminariosQueSeMuestran + 3);
  }

  return (
    <Layout>
      <>
        <FirstSection
          titleContent={
            <>
              <span className="block xl:inline">Actividades del </span>
              {` `}
              <span className="block text-primary-500 xl:inline">GrIS</span>
            </>
          }
          descriptionContent={
            <p>
              En el GrIS realizamos diferentes actividades de difusión,
              transferencia de conocimiento y colaboración con la academia y la
              industria nacional de software a través de diversas actividades
              del programa IS.uy. Además, contamos con un seminario donde se
              tratan diversas temáticas, principalmente relacionadas con las
              líneas de investigación en las cuales trabajamos. A su vez,
              trabajamos con proyectos de asesoramiento con la industria y el
              gobierno, principalmente enfocados en la mejora de procesos.
            </p>
          }
        />

        <BasicSection bgHighlighted fullWidth smallPadding>
          <div className="p-4 bg-white rounded-xl">
            <div className="w-full grid grid-cols-1 gap-4 w-full mb-4 ">
              {ACTIVIDADES.slice(0, 1).map(function (actividad) {
                return (
                  <div className="flex flex-col lg:flex-row items-center   rounded-xl p-6 space-x-6 space-y-3">
                    <div className="w-full lg:w-1/4">
                      <img src={actividad.img[0]} alt="" />
                    </div>

                    <div className="w-full lg:w-3/4 gap-2 flex flex-col items-start">
                      <div
                        className={`text-xl font-bold tracking-tight ${GeneralData.style.layout.text.dark}`}
                      >
                        {actividad.title}
                      </div>
                      <div className={`text-base`}>
                        {actividad.shortDescription}
                      </div>
                      <a
                        className={`text-base ${GeneralData.style.layout.text.primaryLight} ${GeneralData.style.layout.text.primaryHover}`}
                        href="https://is.uy/"
                        target="__blanc"
                      >
                        Ir a la página de IS.uy{' '}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </BasicSection>

        <BasicSection fullWidth smallPadding>
          <div className="">
            <div
              className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
            >
              Charlas y seminarios{' '}
            </div>

            <div className="w-full grid grid-cols-1 gap-4">
              <ol className=" relative border-l border-gray-200 space-y-5">
                {SEMINARIOS.slice(0, cantidadDeSeminariosQueSeMuestran).map(
                  function (data) {
                    return <SeminarioCharla data={data} />;
                  }
                )}
              </ol>

              {cantidadDeSeminariosQueSeMuestran < SEMINARIOS.length && (
                <div className="flex flex-col items-center">
                  <div
                    className={`${GeneralData.style.layout.button(
                      'secondary'
                    )}`}
                    onClick={loadMore}
                  >
                    Cargar más
                  </div>
                </div>
              )}
            </div>
          </div>
        </BasicSection>

        <BasicSection bgHighlighted fullWidth smallPadding>
          <div className="">
            <div
              className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
            >
              Convenios de asesoramiento
            </div>
            <div className="w-full grid grid-cols-1">
              <ul className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4">
                {CONVENIOS.map(function (data) {
                  return <Convenio data={data} />;
                })}
              </ul>
            </div>
          </div>
        </BasicSection>
      </>
    </Layout>
  );
}

export default App;
