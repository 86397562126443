import { useEffect } from 'react';

import { Link } from 'react-router-dom';
import Layout from './../layoutComponents/layout';
import Router from './../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import CursosData from './../../../data/cursos';
import Curso from './../../components/models/Curso';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const CURSOS = CursosData.sort(function (a, b) {
    return a.title.localeCompare(b.title);
  });

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Enseñanza', route: Router.ensenanza },
        { name: 'Cursos', route: '' },
      ]}
    >
      <>
        <FirstSection
          titleContent={
            <>
              <span className="block xl:inline">Cursos del </span>
              {` `}
              <span className="block text-primary-500 xl:inline">GrIS</span>
            </>
          }
        />
        <section className="py-8">
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="flex flex-col items-center sm:-mx-3 ">
              <div>
                <div className="flex flex-row items-center justify-center space-x-2 ">
                  <Link
                    to={Router.cursosDeGrado}
                    className={`${GeneralData.style.layout.button(
                      'secondary'
                    )}`}
                  >
                    Cursos de grado
                  </Link>
                  <Link
                    to={Router.cursosDePostGrado}
                    className={`${GeneralData.style.layout.button(
                      'secondary'
                    )}`}
                  >
                    Cursos de posgrado
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {CURSOS.length > 0 && (
          <BasicSection smallPadding bgHighlighted fullWidth>
            <div className="w-full grid grid-cols-1  lg:grid-cols-2 gap-4 ">
              {CURSOS.map((data) => (
                <div className="w-full">
                  <Curso key={data.title} data={data} />
                </div>
              ))}
            </div>
          </BasicSection>
        )}
      </>
    </Layout>
  );
}

export default App;
