//import '../../css/helpers/video.scss';

const Component = (props) => {
  return (
    <div className="video-responsive">
      <iframe
        src={`https://www.youtube.com/embed/${props.video_id}?autoplay=${
          props.autoplay ? '1' : '0'
        }`}
        frameBorder="0"
        allowFullScreen="allowfullscreen"
        title="Video"
      ></iframe>
    </div>
  );
};

export default Component;
