import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Router from './../core/router';

import Home from './components/pages/home';
import Contacto from './components/pages/contacto';
import Cursos from './components/pages/cursos';
import CursosDeGrado from './components/pages/cursosDeGrado';
import CursosDePostGrado from './components/pages/cursosDePostGrado';
import Investigacion from './components/pages/investigacion';
import Integrantes from './components/pages/integrantes';
import Ensenanza from './components/pages/ensenanza';
import PropuestasDeTesis from './components/pages/propuestasDeTesis';
import Tutorias from './components/pages/tutorias';
import Integrante from './components/pages/integrante';
import Actividades from './components/pages/actividades';
import About from './components/pages/about';
import SeminarioCharla from './components/pages/seminarioCharla';
import Carreras from './components/pages/carreras';
import Curso from './components/pages/Curso';
import Carrera from './components/pages/Carrera';
import Plan from './components/pages/Plan';

const rootComponent = () => (
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route path={Router.plan} element={<Plan />}>
        {' '}
      </Route>
      <Route path={Router.carrera} element={<Carrera />}>
        {' '}
      </Route>
      <Route path={Router.curso} element={<Curso />}>
        {' '}
      </Route>
      <Route
        path={Router.carreras}
        element={
          <Carreras
            pageData={{
              title: 'Carreras del grupo de Ingeniería de Software | GrIS',
              description: 'Descripción de las carreras',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.about}
        element={
          <About
            pageData={{
              title: 'Sobre el grupo de Ingeniería de Software | GrIS',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.home}
        element={
          <Home
            pageData={{
              title: 'Grupo de Ingeniería de Software | GrIS',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.contacto}
        element={
          <Contacto
            pageData={{
              title: 'Contacto',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>

      <Route
        path={Router.cursosDeGrado}
        element={
          <CursosDeGrado
            pageData={{
              title: 'Cursos de grado',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.cursosDePostGrado}
        element={
          <CursosDePostGrado
            pageData={{
              title: 'Cursos de post grado',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.investigacion}
        element={
          <Investigacion
            pageData={{
              title: 'Investigación ',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.integrantes}
        element={
          <Integrantes
            pageData={{
              title: 'Integrantes',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>

      <Route path={Router.integrante} element={<Integrante />}>
        {' '}
      </Route>

      <Route path={Router.charlaSeminario} element={<SeminarioCharla />}>
        {' '}
      </Route>

      <Route
        path={Router.ensenanza}
        element={
          <Ensenanza
            pageData={{
              title: 'Enseñanza',
              description: 'Descripción del grupo',
            }}
          />
        }
      ></Route>
      <Route
        path={Router.cursos}
        element={
          <Cursos
            pageData={{
              title:
                'Cursos de grado y posgrado. Grupo de ingeniería de Software',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.propuestasDeTesis}
        element={
          <PropuestasDeTesis
            pageData={{
              title: 'Propuesta de tesis',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.tutorias}
        element={
          <Tutorias
            pageData={{
              title: 'Tutorias',
              description: 'Descripción del grupo',
            }}
          />
        }
      >
        {' '}
      </Route>
      <Route
        path={Router.actividades}
        element={
          <Actividades
            pageData={{
              title: 'Actividades',
              description: 'Actividades del Gris. ',
            }}
          />
        }
      >
        {' '}
      </Route>
    </Routes>
  </BrowserRouter>
);

export default rootComponent;
