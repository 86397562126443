import React, { useState } from 'react';
import GeneralData from '../../generalData';

function Curso(props) {
  const [open, setOpen] = useState(false);

  const Data = props.data;

  function showMore() {
    setOpen(!open);
  }

  return (
    <div className="w-full h-full bg-white shadow hover:shadow-xl rounded-xl p-5 flex flex-col">
      <div className="w-full flex  flex-row items-center mb-2 space-x-2">
        {Data.grado && (
          <span className="rounded px-2 px-2 py-1 shadow bg-gray-600/50 text-xs text-white">
            Curso de grado
          </span>
        )}
        {Data.posgrado && (
          <span className="rounded px-2 py-1 shadow bg-green-700/50 text-xs text-white">
            Curso de posgrado
          </span>
        )}
      </div>
      <h3
        className={`mb-1  text-2xl font-bold ${GeneralData.style.layout.text.dark}`}
      >
        {Data.title}
      </h3>
      <p
        onClick={showMore}
        className={`text-sm flex-1 font-normal cursor-pointer ${GeneralData.style.layout.text.darkLight}`}
      >
        {Data.shortDescription}
      </p>

      {Data.extraInfo !== undefined && (
        <div className="text-sm mt-1">
          Información extra:{' '}
          <a
            className={`${GeneralData.style.layout.text.primaryLight}`}
            href={Data.extraInfo.url}
          >
            {Data.extraInfo.text}
          </a>
        </div>
      )}

      <div className="flex flex-row mt-4 ">
        <a
          className={` px-4 py-2  ${GeneralData.style.layout.button(
            'primary'
          )} text-base `}
          href={Data.urlMoreInformation}
          target="__blanck"
        >
          Ir a la página del curso{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 ml-1"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="5" y1="12" x2="19" y2="12"></line>
            <polyline points="12 5 19 12 12 19"></polyline>
          </svg>
        </a>
      </div>
    </div>
  );
}

export default Curso;
