import React from 'react';
import { Link } from 'react-router-dom';
import GeneralData from '../../generalData';
import LogoCepap from './../../../img/logo-cpap.png';
import useMember from '../composables/use-member';
import router from '../../router';
import { FaGraduationCap } from 'react-icons/fa';

function Carrera(props) {
  const { name, imgPathSm: imgPath } = props;

  const { getTeachersByNames } = useMember();

  return (
    <Link to={router.carreraGet(name)}>
      <div
        className={`shadow hover:shadow-xl  border border-solid border-primary-50 overflow-hidden rounded-lg  w-full ${GeneralData.style.cardsAnimationsHover}`}
      >
        <div className="overflow-hidden  relative max-h-[250px]">
          <img
            className="h-[250px]  cursor-pointer object-fit"
            src={`${GeneralData.aplicationPublicPath}${imgPath}`}
            alt=""
          />
          <div className="absolute  overflow-hidden inline-block bottom-0 right-0  w-full h-full">
            <div className="absolute top-0 right-0  w-1/3 z-10">
              <img src={LogoCepap} alt="" />
            </div>
            <div className="w-full h-full bg-primary-700 bg-opacity-70 clip-triangle-rectangle"></div>
          </div>
        </div>

        <div className="w-full h-full bg-white   p-4 flex flex-col gap-4">
          <Link className=" flex flex-col gap-1" to={router.carreraGet(name)}>
            <div className="flex items-center gap-2">
              <div
                className={`flex items-center gap-1 rounded text-base text-primary-400 p-1 font-bold bg-primary-50`}
              >
                <FaGraduationCap className="inline" /> Carrera de posgrado
              </div>
            </div>
            <h3
              className={`text-2xl font-bold leading-7  ${GeneralData.style.layout.text.dark}`}
            >
              {name}
            </h3>
          </Link>

          <Link to={router.carreraGet(name)}>
            <div
              className={`text-sm   text-right w-full cursor-pointer ${GeneralData.style.layout.text.link} font-bold`}
            >
              Más información
            </div>
          </Link>
        </div>
      </div>
    </Link>
  );
}

export default Carrera;
