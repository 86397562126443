import { useEffect } from 'react';
import GeneralData from './../../generalData';

import Layout from '../layoutComponents/layout';
import Router from '../../router';
import Card from '../layoutComponents/sections/Card';
import InitPageHelper from '../helpers/PageInitHelper';

import ImgCursosLg from '../../../img/ensenanza/cursos-lg.jpg';
import ImgCursosSm from '../../../img/ensenanza/cursos-sm.jpg';

import ImgTutoriasLg from '../../../img/ensenanza/tutorias-lg.jpg';
import ImgTutoriasSm from '../../../img/ensenanza/tutorias-sm.jpg';

import ImgCarrerasLg from '../../../img/ensenanza/carreras-lg.jpg';
import ImgCarrerasSm from '../../../img/ensenanza/carreras-sm.jpg';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  return (
    <Layout>
      <FirstSection
        titleContent={<span className="block xl:inline">Enseñanza </span>}
        descriptionContent={
          <p>
            Coordinamos carreras de posgrado, dictamos cursos de grado y
            posgrado, realizamos tutorías, y dirigimos tesis de grado y
            posgrado.
          </p>
        }
      />

      <BasicSection bgHighlighted fullWidth>
        <div className="w-full grid grid-cols-1 gap-8 md:grid-cols-3 md:w-3/4 ">
          <Card
            data={{
              titulo: 'Carreras de posgrado',
              shortDescription: '',
              urlImgLg: ImgCarrerasLg,
              urlImgSm: ImgCarrerasSm,
              to: Router.carreras,
              callToActionText: 'Ver carreras',
            }}
          />
          <Card
            data={{
              titulo: 'Cursos',
              shortDescription: '',
              urlImgLg: ImgCursosLg,
              urlImgSm: ImgCursosSm,
              to: Router.cursos,
              callToActionText: 'Ver cursos',
            }}
          />
          <Card
            data={{
              titulo: 'Tesis/tutorías',
              shortDescription: '',
              urlImgLg: ImgTutoriasLg,
              urlImgSm: ImgTutoriasSm,
              to: Router.tutorias,
              callToActionText: 'Ver tutorías',
            }}
          />
        </div>
      </BasicSection>
    </Layout>
  );
}

export default App;
