import router from '../../router';
import members from './../../../data/miembros';

const useMember = () => {
  const getMembersOfPublication = (publication) => {
    return members.filter(function (member) {
      const authorsNameText = publication.authors.author.map(function (person) {
        return person.text.toLowerCase();
      });

      if (member.pid) {
        const authorsNamePid = publication.authors.author.map(function (
          person
        ) {
          return person['@pid'];
        });

        return (
          authorsNameText.includes(member.name.toLowerCase()) ||
          authorsNamePid.includes(member.pid)
        );
      }
      return authorsNameText.includes(member.name.toLowerCase());
    });
  };

  const getTeachersByNames = (namesArray) => {
    const namesLowerCase = namesArray.map((name) => name.toLowerCase());

    return members.filter((member) => {
      return namesLowerCase.includes(member.name.toLowerCase());
    });
  };

  const getTeacherByName = (name) => {
    return members.find(
      (member) => member.name.toLowerCase() == name.toLowerCase()
    );
  };

  const getMembersByNamesPreparedForTeamBoxComponent = (
    teachersCarrerOrCourseObject
  ) => {
    const teacerPreparedObjects = [];

    teachersCarrerOrCourseObject.forEach((teacherName) => {
      const member = getTeacherByName(teacherName.name);
      if (member) {
        teacerPreparedObjects.push({
          name: member.name,
          imgPath: member.img.length > 0 ? member.img[0] : undefined,
          isCoordinator: teacherName.isCoordinator,
          isResponsable: teacherName.isResponsable,
          linkedin: member.linkedin,
          researchgate: member.researchgate,
          isMemberGris: true,
          routeToMemberPage: router.integranteGet(member.name),
        });
      } else {
        teacerPreparedObjects.push({
          name: teacherName.name,
          isCoordinator: teacherName.isCoordinator,
          isResponsable: teacherName.isResponsable,
        });
      }
    });

    return teacerPreparedObjects;
  };

  const getGroupActiveMembers = () =>
    members.filter(function (miembro) {
      return (
        (miembro.type === 'integrante' || miembro.type === 'coordinador') &&
        miembro.status == 'active'
      );
    });

  const getGroupInactiveMembers = () =>
    members.filter(function (miembro) {
      return (
        (miembro.type === 'integrante' || miembro.type === 'coordinador') &&
        miembro.status == 'inactive'
      );
    });

  const getGroupCollaboratorMembers = () =>
    members.filter(function (miembro) {
      return miembro.type === 'colaborador';
    });

  const getTeacherOfCarreaOrCoursesNoCoordinatorNoResponsable = (teachers) => {
    return teachers
      .filter((teacher) => !teacher.isCoordinator && !teacher.isResponsable)
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const getTeacherOfCarreraOrCourseCoordinator = (teachers) => {
    return teachers
      .filter((teacher) => teacher.isCoordinator)
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const getTeacherOfCarreraOrCourseResponsable = (teachers) => {
    return teachers
      .filter((teacher) => teacher.isResponsable)
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const getTeachersAll = (teachers) => {
    return teachers.sort((a, b) => {
      if (
        (a.isCoordinator || a.isResponsable) &&
        !(b.isCoordinator || b.isResponsable)
      ) {
        return -1;
      }

      if (
        !(a.isCoordinator || a.isResponsable) &&
        (b.isCoordinator || b.isResponsable)
      ) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
  };

  return {
    getMembersOfPublication,
    getTeachersByNames,
    getMembersByNamesPreparedForTeamBoxComponent,
    getGroupActiveMembers,
    getGroupInactiveMembers,
    getGroupCollaboratorMembers,
    getTeacherOfCarreaOrCoursesNoCoordinatorNoResponsable,
    getTeacherOfCarreraOrCourseCoordinator,
    getTeacherOfCarreraOrCourseResponsable,
    getTeachersAll,
  };
};

export default useMember;
