import Axios from 'axios';
import Teams from './miembros';

let data = [];
let articles = [];

const getData = async function (nameAuthor) {
  return Axios.get(
    `https://dblp.org/search/publ/api?q=${nameAuthor}&format=json`
  )
    .then(function (response) {
      let data = response.data.result;

      if (data.status['@code'] == '200') {
        data = data.hits;
        if (data.hit.length > 0) {
          data.hit.forEach(function (pub) {
            let pubsIds = articles.map(function (pubSub) {
              return pubSub['@id'];
            });

            if (!pubsIds.includes(pub['@id'])) {
              articles.push(pub);
            }
          });
        }
      } else {
        console.error('Algo no está bien');
      }
    })
    .catch(function (error) {
      console.error(error.message);
    });
};

const wraperFunction = function () {
  const teamsName = Teams.map(function (team) {
    return team.name;
  });

  for (let item of teamsName) {
    getData(item);
  }

  return articles;
};

data = wraperFunction();

export default data;
