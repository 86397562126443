import React from 'react';
import GeneralData from '../../../generalData';

import { FaIdCard, FaLinkedin, FaResearchgate, FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function TeamForCarrereAndCourse(props) {
  const {
    imgPath,
    name,
    linkedin,
    researchgate,
    isCoordinator,
    isResponsable,
    isMemberGris,
    routeToMemberPage,
  } = props;

  return (
    <figure className="relative flex flex-col  justify-center bg-white  overflow-hidden shadow hover:shadow-xl rounded-lg p-2 lg:p-2  border  border-solid border-primary-50 relative">
      <figcaption className="flex items-center  gap-2">
        <div className="relative flex-none">
          {isMemberGris && (
            <div className="rounded absolute bottom-[-10px] left-3    font-bold p-0 leading-3 text-[8px] text-primary-600">
              GrIS
            </div>
          )}

          <img
            src={
              imgPath
                ? process.env.PUBLIC_URL + imgPath
                : GeneralData.generalUserImg
            }
            alt=""
            className=" w-10 h-10 rounded-full object-cover flex-none border  border-solid border-primary-100"
            loading="lazy"
          />
        </div>

        <div className="flex-auto">
          <div
            className={`text-xs font-regular mt-1 ${GeneralData.style.layout.text.dark} font-bold`}
          >
            {name}{' '}
            {(isCoordinator || isResponsable) && (
              <span className="text-primary-400">
                {' '}
                <FaStar className="inline my-0 py-0 text-yellow-500" />
                {isCoordinator && 'Cordinador/a'}
                {isResponsable && 'Responsable'}
              </span>
            )}
          </div>

          <div className="flex items-center gap-1">
            {linkedin && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={linkedin}
              >
                <FaLinkedin />
              </a>
            )}
            {researchgate && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={researchgate}
              >
                <FaResearchgate />
              </a>
            )}

            {routeToMemberPage && (
              <Link to={routeToMemberPage}>
                <span
                  className={`${GeneralData.style.layout.text.primary} text-xl `}
                >
                  <FaIdCard />
                </span>
              </Link>
            )}
          </div>
        </div>
      </figcaption>
    </figure>
  );
}

export default TeamForCarrereAndCourse;
