import {
  FaLinkedin,
  FaEnvelope,
  FaPhone,
  FaMapMarker,
  FaClock,
} from 'react-icons/fa';
import useCpapData from '../../composables/use-cpap-data';

const LineContact = ({ Icon, text, link }) => {
  return (
    <div className="flex gap-3 items-center ">
      <span className="text-primary-600">{Icon}</span>

      <div>
        <p className="text-sm">{link ? <a href={link}>{text}</a> : text}</p>
      </div>
    </div>
  );
};

const { contactInfo } = useCpapData();

const ContactSection = () => {
  return (
    <div className="w-full mt-4">
      <div className="flex flex-col gap-4 text-lg">
        <LineContact Icon={<FaEnvelope />} text={contactInfo.email} />
        <LineContact
          Icon={<FaLinkedin />}
          text={`Linkedin`}
          link={contactInfo.linkedin}
        />
        <LineContact Icon={<FaPhone />} text={contactInfo.phone} />
        <LineContact Icon={<FaMapMarker />} text={contactInfo.address} />
        <LineContact Icon={<FaClock />} text={contactInfo.bussinessHours} />
      </div>
    </div>
  );
};

export default ContactSection;
