import NavLink from './../../helpers/NavLink';
import GeneralData from './../../../generalData';

const Footer = (props) => (
  <>
    <section className="bg-white">
      <div className="max-w-screen-xl px-4 py-16 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8 ">
        <nav className="flex flex-wrap justify-center -mx-5 -my-2 font-medium">
          {GeneralData.menu.map((data) => (
            <NavLink
              key={data.name}
              exact={true}
              to={data.route}
              activeClassName={GeneralData.style.footer.menu.listItemActive}
              className={GeneralData.style.footer.menu.listItem}
            >
              {data.name}
            </NavLink>
          ))}
        </nav>
        <div className="flex justify-center mt-8 space-x-6 font-regular">
          {GeneralData.socialMedia.map(function (social, index) {
            return (
              <a
                key={`icon${index}`}
                href={social.url}
                className={`${GeneralData.style.layout.text.primary} text-4xl ${GeneralData.style.layout.text.primaryHover}`}
              >
                {social.icon}
              </a>
            );
          })}
        </div>
        <p className="mt-8 text-base leading-6 text-center text-gray-400">
          © {new Date().getFullYear()} Fing | GrIS. Todos los derechos
          reservados.{' '}
          <a href="https://mwebs.com.uy/" className={`cursor-pointer `}>
            Diseño y desarrollo{' '}
          </a>{' '}
          por{' '}
          <a
            href="https://mwebs.com.uy/"
            className={`cursor-pointer font-bold ${GeneralData.style.layout.text.primaryLight}`}
          >
            mwebs
          </a>
          .
        </p>
      </div>
    </section>
  </>
);

export default Footer;
