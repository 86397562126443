import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import GeneralData from './../../generalData';
import InitPageHelper from '../helpers/PageInitHelper';
import BasicSection from '../layoutComponents/sections/BasicSection';

import Layout from './../layoutComponents/layout';
import Router from './../../router';

import './../../../App.css';

import ImgHome from './../../../img/home/corredor-inco-2.jpg';
import Card from '../layoutComponents/sections/Card';

import ImgCursosLg from '../../../img/ensenanza/cursos-lg.jpg';
import ImgCursosSm from '../../../img/ensenanza/cursos-sm.jpg';

import ImgTutoriasLg from '../../../img/ensenanza/tutorias-lg.jpg';
import ImgTutoriasSm from '../../../img/ensenanza/tutorias-sm.jpg';

import ImgCarrerasLg from '../../../img/ensenanza/carreras-lg.jpg';
import ImgCarrerasSm from '../../../img/ensenanza/carreras-sm.jpg';

import actividades from '../../../data/actividades';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const ACTIVIDADES = actividades;

  return (
    <Layout>
      <>
        <section className={`${GeneralData.style.pages.firstSectionContainer}`}>
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="flex flex-wrap items-center sm:-mx-3">
              <div className="w-full md:w-1/2 md:px-3">
                <div className="w-full pb-6 space-y-6 sm:max-w-md lg:max-w-lg md:space-y-4 lg:space-y-8 xl:space-y-9 sm:pr-5 lg:pr-0 md:pb-0">
                  <h1
                    className={`text-4xl font-bold tracking-tight ${GeneralData.style.layout.text.dark} sm:text-5xl md:text-4xl lg:text-5xl xl:text-6xl `}
                  >
                    <span className="block xl:inline">Grupo de </span>
                    {` `}
                    <span
                      className={`block ${GeneralData.style.layout.text.primary} xl:inline`}
                    >
                      Ingeniería de Software
                    </span>
                  </h1>

                  <p
                    className={`mx-auto text-base ${GeneralData.style.layout.text.grey} sm:max-w-md  md:max-w-3xl`}
                  >
                    Grupo que realiza investigación en temas relativos a la
                    ingeniería de software. Principalmente enfocados en procesos
                    de desarrollo de software y su mejora, calidad de software,
                    evaluación de técnicas de ingeniería de software, ingeniería
                    de software basada en evidencia y educación en ingeniería de
                    software.
                  </p>
                  <div className="space-y-3 sm:space-y-0 relative flex flex-col sm:flex-row sm:space-x-4 ">
                    <Link
                      to={Router.about}
                      className={GeneralData.style.layout.button('primary')}
                    >
                      Ver más
                    </Link>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2  ">
                <div className="w-full h-auto overflow-hidden rounded-md  sm:rounded-xl">
                  <img src={ImgHome} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <BasicSection bgHighlighted>
          <>
            <h3 className={`${GeneralData.style.pages.otherSectionTitle}`}>
              Investigación
            </h3>
            <p
              className={`text-lg text-center ${GeneralData.style.layout.text.grey}   w-full lg:w-3/4`}
            >
              Temas en los que estamos trabajando actualmente: ingeniería de
              software experimental, procesos de desarrollo de software, calidad
              de software, educación y entrenamiento en ingeniería de software e
              ingeniería de software basada en evidencia.
            </p>

            <Link
              to={Router.investigacion}
              className={`${GeneralData.style.layout.button('primary')} `}
            >
              Ir a investigación
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 ml-1"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </Link>
          </>
        </BasicSection>

        <BasicSection>
          {' '}
          <h3 className={`${GeneralData.style.pages.otherSectionTitle}`}>
            Enseñanza
          </h3>
          <p
            className={`text-lg text-center ${GeneralData.style.layout.text.grey}   w-full lg:w-3/4`}
          >
            Coordinamos carreras de posgrado, dictamos cursos de grado y
            posgrado, realizamos tutorías, y dirigimos tesis de grado y
            posgrado.
          </p>
          <div className="w-full p-3 flex flex-col items-center">
            <div className="w-full grid grid-cols-1 gap-8 lg:gap-4  md:grid-cols-3 md:w-3/4 ">
              <Card
                data={{
                  titulo: 'Carreras de posgrado',
                  shortDescription: '',
                  urlImgLg: ImgCarrerasLg,
                  urlImgSm: ImgCarrerasSm,
                  to: Router.carreras,
                  callToActionText: 'Ver carreras',
                }}
              />
              <Card
                data={{
                  titulo: 'Cursos',
                  shortDescription: '',
                  urlImgLg: ImgCursosLg,
                  urlImgSm: ImgCursosSm,
                  to: Router.cursos,
                  callToActionText: 'Ver cursos',
                }}
              />
              <Card
                data={{
                  titulo: 'Tesis y tutorías',
                  shortDescription: '',
                  urlImgLg: ImgTutoriasLg,
                  urlImgSm: ImgTutoriasSm,
                  to: Router.tutorias,
                  callToActionText: 'Ver tutorías',
                }}
              />
            </div>
          </div>
        </BasicSection>

        <BasicSection bgHighlighted>
          <h3 className={`${GeneralData.style.pages.otherSectionTitle}`}>
            Actividades
          </h3>
          <p
            className={`text-lg text-center ${GeneralData.style.layout.text.grey}   w-full lg:w-3/4`}
          >
            En el GrIS realizamos diferentes actividades de difusión,
            transferencia de conocimiento y colaboración con la academia y la
            industria nacional de software a través de diversas actividades del
            programa IS.uy. Además, contamos con un seminario donde se tratan
            diversas temáticas, principalmente relacionadas con las líneas de
            investigación en las cuales trabajamos. A su vez, trabajamos con
            proyectos de asesoramiento con la industria y el gobierno,
            principalmente enfocados en la mejora de procesos.
          </p>

          <Link
            to={Router.actividades}
            className={`${GeneralData.style.layout.button('primary')} `}
          >
            Ir a actividades
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 ml-1"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <line x1="5" y1="12" x2="19" y2="12"></line>
              <polyline points="12 5 19 12 12 19"></polyline>
            </svg>
          </Link>
        </BasicSection>
      </>
    </Layout>
  );
}

export default App;
