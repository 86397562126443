const data = [
  {
    title: 'Deuda técnica de software',
    shortDescription: '',
    longDescription: `La deuda técnica de software propone una metáfora para comprender en términos
    financieros (deuda) los riesgos y consecuencias de afectar la calidad interna del software en favor de acelerar su proceso de entrega, pero comprometiendo su capacidad de evolución. Investigamos cómo los profesionales perciben y gestionan la deuda técnica en sus proyectos de software, así como los desafíos que se presentan para su adecuado tratamiento.`,
    references: [],
  },
  {
    title: 'Diseño de software',
    shortDescription: '',
    longDescription: `El diseño de software y su rol fundamental dentro de los distintos procesos de desarrollo de software. El PSP, es un conjunto de prácticas disciplinadas para la gestión del tiempo y mejora de la productividad personal de los ingenieros de software. 
    Estudiamos el efecto de un buen diseño en la calidad del software, la obtención de productos de software robustos, fáciles de mantener, con pocos errores y menos costosos de desarrollar.`,
    references: [],
  },
  {
    title: 'Ingeniería de software basada en evidencia',
    shortDescription: '',
    longDescription: `Ingeniería de Software Basada en Evidencia (EBSE) tiene como propósito mejorar la toma de decisiones relacionada al desarrollo y mantenimiento de software integrando la mejor evidencia actual de la investigación con experiencias prácticas y valores de las organizaciones y personas. Investigamos el uso de EBSE para abordar problemas relevantes en diferentes contextos: academia, industria, gobierno; así como estudiar los  procesos a utilizar para recolectar y utilizar evidencia, y también de los beneficios y dificultades al usar EBSE.`,
    references: [],
  },
  {
    title: 'Procesos de desarrollo de software',
    shortDescription: '',
    longDescription: `Estudiamos los procesos de desarrollo de software y cómo las distintas actividades afectan la calidad del software construido. En esta linea en particular, trabajamos con el Personal Software Process (PSP), el Team Software Process (TSP) y adaptaciones y extensiones de Scrum.`,
    references: [],
  },
  {
    title: 'Evaluación empírica de técnicas de desarrollo de software',
    shortDescription: '',
    longDescription: `Trabajamos en la evaluación de distintas técnicas de desarrollo de software. Estudiamos aspectos vinculados al costo y beneficio de aplicarlas mediante experimentos controlados. Investigamos el impacto de distintas técnicas de pruebas de software mediante el análisis de los costos y los beneficios de aplicarlas.`,
    references: [],
  },
  {
    title: 'Educación en ingeniería de software',
    shortDescription: '',
    longDescription: `Estudiamos formas de evaluar carreras en ingeniería de software con el objetivo de mejorar el aprendizaje y desarrollo de los estudiantes. Teniendo en cuenta las buenas prácticas de evaluación y consideramos también las guías curriculares de referencia existentes, tanto para las carreras de grado y posgrado en ingeniería de software.`,
    references: [],
  },
];

export default data;
