import React, { useState } from 'react';
import GeneralData from '../../generalData';
import Miembros from '../../../data/miembros';
import Router from '../../router';
import { Link } from 'react-router-dom';
import IsUyActividades from './../../../data/actividades';
import ModalComponent from '../helpers/ModalComponent';
import YouTubeVideo from './../helpers/VideoYotube';
import SeminarioContent from './../pages/seminarios/seminarioContent';

function Componente(props) {
  const [open, setOpen] = useState(false);

  const Data = props.data;

  const options = { year: 'numeric', month: 'long' };

  const dateStart = new Date(Data.dateStart).toLocaleDateString(
    'es-ES',
    options
  );

  const autoresTexto = Data.participants.join(', ');

  const typeActivityLogo = IsUyActividades.filter(function (item) {
    return item.id.toLocaleLowerCase() == Data.isUyType.toLowerCase();
  });

  const Techers = Miembros.filter(function (miembro) {
    let EnMinuscula = Data.participants.map(function (person) {
      return person.toLowerCase();
    });
    return EnMinuscula.includes(miembro.name.toLowerCase());
  });

  function showMore() {
    setOpen(!open);
  }

  return (
    <li className=" ml-4 space-y-1">
      <div className=" absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white"></div>
      <time className="space-x-1 text-sm font-normal leading-none text-gray-400 ">
        <span className=""> {dateStart}</span>
      </time>
      <div className="relative shadow bg-white  rounded-xl p-2 space-y-2">
        <div className=" absolute -top-4 right-2 flex flex-row items-center space-x-1">
          {Techers.length > 0 && (
            <>
              <div className="space-x-2 flex flex-row items-center ">
                <div className="flex flex-row items-center space-x-2">
                  {Techers.map((team) => (
                    <Link to={Router.integranteGet(team.name)}>
                      <img
                        src={
                          team.img.length > 0
                            ? process.env.PUBLIC_URL + team.img[0]
                            : GeneralData.generalUserImg
                        }
                        alt=""
                        className="flex-none w-6 h-6 rounded-full object-cover"
                        loading="lazy"
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}

          {typeActivityLogo.length > 0 && (
            <div className="w-[50px]">
              <img
                src={typeActivityLogo[0].img[0]}
                alt=""
                className="bg-white rounded-xl p-1 "
              />
            </div>
          )}
        </div>

        <div className="flex flex-col lg:flex-row items-start lg:items-center space-x-0 lg:space-x-5 space-y-4 lg:space-y-0">
          <Link
            to={Router.charlaSeminarioGet(Data.title)}
            className="h-[85px] w-[150px] flex-none bg-white rounded-xl overflow-hidden shadow"
          >
            <img
              src={Data.img.length > 0 ? Data.img[0] : GeneralData.noImagen}
              alt=""
              className="w-full cursor-pointer "
            />
          </Link>
          <div>
            <div className="space-x-1">
              <Link to={Router.charlaSeminarioGet(Data.title)}>
                <h3
                  className={` text-lg font-bold ${GeneralData.style.layout.text.dark}`}
                >
                  {Data.title}
                </h3>
              </Link>
            </div>

            {Data.shortDescription !== '' && (
              <div className="text-sm cursor-pointer whitespace-pre-line">
                <span>
                  {`${Data.shortDescription.slice(0, 100)} `}{' '}
                  <span
                    className={`ml-2 ${GeneralData.style.layout.text.primaryLight}`}
                  >
                    {Data.youTubeVideoId === '' ? (
                      <Link to={Router.charlaSeminarioGet(Data.title)}>
                        ...ampliar{' '}
                      </Link>
                    ) : (
                      <span onClick={showMore}> ...ampliar </span>
                    )}
                  </span>{' '}
                </span>
              </div>
            )}

            <div className="space-y-0 mt-1">
              <p
                className={`text-xs  ${GeneralData.style.layout.text.darkLight} `}
              >
                {Data.place !== '' && (
                  <span>
                    Lugar: <strong>{Data.place}</strong>
                  </span>
                )}
              </p>

              <p
                className={`cursor-pointer text-xs  ${GeneralData.style.layout.text.darkLight} `}
                onClick={showMore}
              >
                {open ? (
                  <span>Oradores: {autoresTexto}</span>
                ) : (
                  <>
                    <span>Oradores: </span>
                    <span className="">
                      {` ${autoresTexto.slice(0, 80)} `}{' '}
                      {autoresTexto.length > 80 ? '...' : ''}
                    </span>
                  </>
                )}
              </p>
              {Data.youTubeVideoId !== '' && (
                <ModalComponent
                  open={open}
                  modalContent={
                    <SeminarioContent Techers={Techers} CHARLA={Data} />
                  }
                  activaModal={
                    <div
                      className={` text-xs cursor-pointer  ${GeneralData.style.layout.text.primaryLight}   `}
                    >
                      Ver video{' '}
                    </div>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default Componente;
