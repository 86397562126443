import React from 'react';

import TeamForCarrereAndCourse from './TeamForCarrereAndCourse';
import useMember from '../../composables/use-member';

function TeamBoxForCarrerAndCourses(props) {
  const { teacersObjects } = props;

  const { getMembersByNamesPreparedForTeamBoxComponent } = useMember();
  const teacherObjectReadyToBoxComponent =
    getMembersByNamesPreparedForTeamBoxComponent(teacersObjects);
  return (
    <>
      {teacherObjectReadyToBoxComponent.map((teacherObject, index) => (
        <TeamForCarrereAndCourse
          key={`TeamForCarrereAndCourse--${index}`}
          {...teacherObject}
        />
      ))}
    </>
  );
}

export default TeamBoxForCarrerAndCourses;
