import cursosDeCarreras from '../../../data/cursosDeCarreras';

const parseDate = (date) => {
  const options = { day: 'numeric', year: 'numeric', month: 'long' };

  return new Date(date).toLocaleDateString('es-ES', options);
};

const getDateStartText = (dateStart) => {
  return `Inicio ${parseDate(dateStart)}`;
};

const canISignUp = (dateStart) => {
  // TODO validate
  return true;

  /*const today = new Date().getTime();
  const init = new Date(dateStart).getTime();

  if (today > init) {
    return false;
  }

  return true;*/
};

const getCareerCoursesFromIds = (idsArray) => {
  return cursosDeCarreras
    .filter((course) => idsArray.includes(course.id))
    .sort((a, b) => a.name.localeCompare(b.name));
};

const cursoByName = (name) => {
  return cursosDeCarreras.find(
    (curso) => curso.name.toLocaleLowerCase() == name.toLocaleLowerCase()
  );
};

const cursoById = (courseId) => {
  return cursosDeCarreras.find((curso) => curso.id == courseId);
};

const getCourseTeachers = (course) => {
  const teachers = course.teachers;

  return teachers;
};

export default function useCurso() {
  return {
    getDateStartText,
    canISignUp,
    getCareerCoursesFromIds,
    cursoByName,
    parseDate,
    cursoById,
    getCourseTeachers,
  };
}
