import React, { useState } from 'react';
import GeneralData from '../../generalData';
import Publicaciones from '../../../data/publicaciones';
import Router from '../../router';

import {
  FaAddressCard,
  FaLinkedin,
  FaTwitter,
  FaGlobe,
  FaResearchgate,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';

import ModalComponent from '../helpers/ModalComponent';
import { Link } from 'react-router-dom';

function Team(props) {
  const [open, setOpen] = useState(false);

  const teamPublications = Publicaciones.filter(function (publicacion) {
    return publicacion.info.authors.author
      .map(function (person) {
        return person.text.toLowerCase();
      })
      .includes(props.team.name.toLowerCase());
  });

  function showMore() {
    setOpen(!open);
  }

  return (
    <figure className="relative flex flex-col bg-white shadow hover:shadow-xl rounded-xl p-4 lg:p-6 ">
      <figcaption className="flex items-center space-x-4">
        <Link to={Router.integranteGet(props.team.name)} className="flex-none">
          <img
            src={
              props.team.img.length > 0
                ? process.env.PUBLIC_URL + props.team.img[0]
                : GeneralData.generalUserImg
            }
            alt=""
            className=" w-16 h-16 rounded-full object-cover"
            loading="lazy"
          />
        </Link>
        <div className="flex-auto">
          <Link to={Router.integranteGet(props.team.name)}>
            <div
              className={`text-xl ${GeneralData.style.layout.text.dark} font-bold`}
            >
              {props.team.name}
            </div>
          </Link>
          <div className="flex items-center space-x-1">
            {props.team.linkedin !== '' && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={props.team.linkedin}
              >
                <FaLinkedin />
              </a>
            )}
            {props.team.researchgate !== '' && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={props.team.researchgate}
              >
                <FaResearchgate />
              </a>
            )}
            {props.team.cvuy !== '' && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={props.team.cvuy}
              >
                <FaAddressCard />
              </a>
            )}
            {props.team.web !== '' && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={props.team.web}
              >
                <FaGlobe />
              </a>
            )}
            {props.team.twitter !== '' && (
              <a
                className={`${GeneralData.style.layout.text.primary} text-xl `}
                href={props.team.twitter}
              >
                <FaTwitter />
              </a>
            )}
          </div>
        </div>
      </figcaption>
      <blockquote
        className={`mt-3 ${GeneralData.style.layout.text.darkLight} cursor-pointer text-sm`}
      >
        <p onClick={showMore}>
          {open ? (
            props.team.shortDescription
          ) : (
            <span>{`${props.team.shortDescription.slice(0, 100)} `} ...</span>
          )}
        </p>
      </blockquote>

      <ModalComponent
        modalContent={
          <div className="w-full">
            <figcaption className="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 space-x-0 md:space-x-4">
              <img
                src={
                  props.team.img.length > 0
                    ? process.env.PUBLIC_URL + props.team.img[0]
                    : GeneralData.generalUserImg
                }
                alt=""
                className="flex-none w-24 h-24 rounded-full object-cover"
                loading="lazy"
              />
              <div className="space-y-3">
                <div>
                  <div
                    className={`text-2xl ${GeneralData.style.layout.text.dark} font-bold mb-1`}
                  >
                    {props.team.name}
                  </div>

                  <div
                    className={`text-base ${GeneralData.style.layout.text.grey} pl-2`}
                  >
                    {props.team.shortDescription}
                  </div>
                </div>

                <div>
                  <div
                    className={`text-lg ${GeneralData.style.layout.text.dark} mt-3  mb-1`}
                  >
                    <strong>Redes</strong>
                  </div>
                  <div className="pl-2 flex items-center space-x-2 mb-3">
                    {props.team.linkedin !== '' && (
                      <a
                        className={`${GeneralData.style.layout.text.primary} text-3xl `}
                        href={props.team.linkedin}
                      >
                        <FaLinkedin />
                      </a>
                    )}
                    {props.team.researchgate !== '' && (
                      <a
                        className={`${GeneralData.style.layout.text.primary} text-3xl `}
                        href={props.team.researchgate}
                      >
                        <FaResearchgate />
                      </a>
                    )}
                    {props.team.cvuy !== '' && (
                      <a
                        className={`${GeneralData.style.layout.text.primary} text-3xl `}
                        href={props.team.cvuy}
                      >
                        <FaAddressCard />
                      </a>
                    )}
                    {props.team.web !== '' && (
                      <a
                        className={`${GeneralData.style.layout.text.primary} text-3xl `}
                        href={props.team.web}
                      >
                        <FaGlobe />
                      </a>
                    )}
                    {props.team.twitter !== '' && (
                      <a
                        className={`${GeneralData.style.layout.text.primary} text-3xl `}
                        href={props.team.twitter}
                      >
                        <FaTwitter />
                      </a>
                    )}
                  </div>
                </div>

                <div>
                  <div
                    className={`text-lg ${GeneralData.style.layout.text.dark} mb-1`}
                  >
                    <strong>Información </strong>
                  </div>
                  <div
                    className={`w-full mb-5  pl-2 space-y-1 ${GeneralData.style.layout.text.grey}`}
                  >
                    <div className="text-sm">
                      Grado:{' '}
                      {props.team.rankDescription
                        ? props.team.rankDescription
                        : props.team.rank}
                    </div>{' '}
                    <div className="text-sm">
                      Rol:{' '}
                      {props.team.typeDescription
                        ? props.team.typeDescription
                        : props.team.status == 'inactive'
                        ? 'ex-integrante'
                        : props.team.type}
                    </div>
                    {props.team.email !== '' && (
                      <div className="text-sm">
                        <FaEnvelope className="inline" /> {props.team.email}
                      </div>
                    )}
                    {props.team.phone !== '' && (
                      <div className="text-sm">
                        <FaPhone className="inline" /> {props.team.phone}
                      </div>
                    )}
                  </div>
                </div>

                {teamPublications.length > 0 && (
                  <div className="">
                    <div
                      className={`text-lg ${GeneralData.style.layout.text.dark} mb-2`}
                    >
                      <strong>Publicaciones</strong>
                    </div>

                    <div className="pl-1 flex flex-col w-full items-start">
                      <Link
                        to={Router.integranteGet(props.team.name)}
                        className={`${GeneralData.style.layout.button(
                          'primary'
                        )} `}
                      >
                        Publicaciones de {props.team.name}{' '}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-5 h-5 ml-1"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <line x1="5" y1="12" x2="19" y2="12"></line>
                          <polyline points="12 5 19 12 12 19"></polyline>
                        </svg>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </figcaption>
          </div>
        }
        activaModal={
          <div
            className={`${GeneralData.style.layout.text.primaryLight} cursor-pointer`}
          >
            Ver más
          </div>
        }
      />
    </figure>
  );
}

export default Team;
