const data = [
  {
    name: 'Cecilia Apa',
    shortDescription:
      'Docente en la Facultad de Ingeniería, Universidad de la República. Estudiante del Doctorado en Informática del PEDECIBA. Mi área de docencia es la Ingeniería de Software, en particular las disciplinas que refieren a Procesos de Software, Aseguramiento de la Calidad de Software, Mejoras de Procesos de Software, Verificación y Validación de Software y Gestión de Proyectos de Software. Mi área de investigación actual es la Deuda Técnica de Software.',
    rank: 2,
    img: ['/images/integrantes/Apa.jpg'],
    email: 'ceapa@fing.edu.uy',
    phone: '',
    id: 'ceapa@fing.edu.uy',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'active',
    linkedin: 'https://www.linkedin.com/in/ceciliaapa/es?originalSubdomain=uy',
    researchgate: 'https://www.researchgate.net/profile/Cecilia-Apa',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
    orderRank: 3,
  },
  {
    name: 'Diego Vallespir',
    shortDescription:
      'Coordinador del Programa IS.uy. Responsable académico de la Especialización y la Maestría en ingeniería de software, Fing, Udelar.',
    rank: 4,
    rankDescription: 'Profesor agregado, DT',
    img: ['/images/integrantes/Vallespir.jpg'],
    email: 'dvallesp@fing.edu.uy',
    phone: '',
    id: 'dvallesp@fing.edu.uy',
    type: 'coordinador' /* coordinador - integrante - colaborador */,
    typeDescription: 'responsable',
    status: 'active',
    linkedin: 'https://www.linkedin.com/in/diego-vallespir-126a715/',
    researchgate: 'https://www.researchgate.net/profile/Diego-Vallespir',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
    orderRank: 5,
  },
  {
    name: 'Silvana Moreno',
    shortDescription:
      'Docente de Proyecto de Ingeniería de Software, Principios y fundamentos del PSP en Facultad de Ingeniería de la Universidad de la República. Tutora de tesis de grado y co-tutora de tesis de posgrado.',
    rank: 2,
    img: ['/images/integrantes/Silvana.png'],
    email: 'smoreno@fing.edu.uy',
    phone: '',
    id: 'smoreno@fing.edu.uy',
    type: 'coordinador' /* coordinador - integrante - colaborador */,
    status: 'active',
    linkedin:
      'https://www.linkedin.com/in/silvana-moreno-92bb5620/?originalSubdomain=uy',
    researchgate: 'https://www.researchgate.net/profile/Silvana-Moreno-3',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
    orderRank: 4,
  },
  {
    name: 'Sebastián Pizard',
    shortDescription:
      'Docente de Ingeniería de Software en Facultad de Ingeniería de la Universidad de la República: instrucción de estudiantes en temas afines a la ingeniería de software y dirección de grupos de alumnos en proyectos de desarrollo de software con metodologías estilo RUP. Formación en Relevamiento de Requerimientos, Gestión de TI, Gestión de Calidad de Software y Liderazgo de Equipos. Experiencia en dirección técnica de equipos de desarrollo, mantenimiento e implantación de software. Habilidad en el relevamiento de procesos, definición de procedimientos y auditoría de procesos. ',
    rank: 2,
    img: ['/images/integrantes/Pizard.jpg'],
    email: 'spizard@fing.edu.uy',
    phone: '',
    id: 'spizard@fing.edu.uy',
    type: 'integrante' /* coordinador - integrante - colaborador */,
    status: 'active',
    linkedin:
      'https://www.linkedin.com/in/sebasti%C3%A1n-pizard-a0205419/?originalSubdomain=uy',
    researchgate: 'https://www.researchgate.net/profile/Sebastian-Pizard',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
    orderRank: 2,
  },
  {
    name: 'Jorge Triñanes',
    shortDescription: 'Profesor en Universidad de la República.',
    rank: 2,
    img: [],
    email: '',
    phone: '',
    id: '',
    type: 'integrante' /* coordinador - integrante - colaborador */,
    status: 'active',
    linkedin: 'https://www.linkedin.com/in/jorge-tri%C3%B1anes-8471b234/',
    researchgate: 'https://www.researchgate.net/profile/Jorge-Trinanes',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'Angie Lecot',
    shortDescription:
      'Ingeniera en Computación y  Especialista en Ingeniería de Software.',
    rank: 2,
    img: ['/images/integrantes/Lecot.jpeg'],
    email: 'alecot@fing.edu.uy',
    phone: '',
    id: 'alecot@fing.edu.uy',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'active',
    linkedin: 'https://www.linkedin.com/in/ing-angie-lecot-1400a38/',
    researchgate: '',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'María Carolina Valverde',
    shortDescription:
      'Ingeniera en Computación,  Especialista en Ingeniería de Software y Magister en Ingeniería de Software.',
    rank: 2,
    img: ['/images/integrantes/Valverde.jpeg'],
    email: 'mvalverde@fing.edu.uy',
    phone: '',
    id: 'mvalverde@fing.edu.uy',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'active',
    linkedin: 'https://www.linkedin.com/in/msc-carolina-valverde-pmp-19303649/',
    researchgate: 'https://www.researchgate.net/profile/Maria-Valverde',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
    pid: '152/3880',
  },
  {
    name: 'Lucía Camilloni',
    shortDescription:
      'Ingeniera en Computación,  Especialista en Ingeniería de Software y Magister en Ingeniería de Software.',
    rank: 2,
    img: ['/images/integrantes/Camilloni.jpeg'],
    email: 'lcamilloni@fing.edu.uy',
    phone: '',
    id: 'lcamilloni@fing.edu.uy',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'inactive',
    linkedin: 'https://www.linkedin.com/in/luciacamilloni/',
    researchgate: 'https://www.researchgate.net/profile/Lucia-Camilloni',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'Javier Barreiro',
    shortDescription:
      '​​Ingeniero en Computación y Especialista en Gestión de Tecnologías.',
    rank: 3,
    img: ['/images/integrantes/Barreiro.jpeg'],
    email: 'jbarreir@fing.edu.uy',
    phone: '',
    id: 'jbarreir@fing.edu.uy',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'active',
    linkedin: 'https://www.linkedin.com/in/javierbarreiro/',
    researchgate: '',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'María de las Nieves Freira',
    shortDescription: '​​Ingeniera en Computación, Magister en Computación.',
    rank: 3,
    img: [],
    email: 'email:freira@fing.edu.uy',
    phone: '',
    id: 'email:freira@fing.edu.uy',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'active',
    linkedin: '',
    researchgate: '',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'Vanessa Casella',
    shortDescription: '​​Ingeniera en Computación, Magister en Computación.',
    rank: 1,
    img: ['/images/integrantes/Casella.jpeg'],
    email: '',
    phone: '',
    id: '',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'inactive',
    linkedin: 'https://www.linkedin.com/in/vanessa-casella-96244625/',
    researchgate: '',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'Valeria Bonjour',
    shortDescription: '​​Ingeniera en Computación, Magister en Computación.',
    rank: 1,
    img: ['/images/integrantes/Bonjour.jpeg'],
    email: '',
    phone: '',
    id: '',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'inactive',
    linkedin: 'https://www.linkedin.com/in/valeria-giselle-bonjour-santa-cruz/',
    researchgate: '',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
  {
    name: 'Fernando Acerenza',
    shortDescription: '​​Ingeniero en Computación, Magister en Computación.',
    rank: 1,
    img: ['/images/integrantes/Acerenza.jpeg'],
    email: '',
    phone: '',
    id: '',
    type: 'integrante' /* coordinar - integrante - colaborador */,
    status: 'inactive',
    linkedin: 'https://www.linkedin.com/in/fernandoacerenza',
    researchgate: '',
    cvuy: '',
    twitter: '',
    web: '',
    affiliation: '',
    idInvestigador: '',
    idOrcid: '',
  },
];

//TODO ver cómo hacer esto en una sola función
const dataOrdenadaPrincipales = data
  .filter((miembro) => miembro.orderRank !== undefined)
  .sort((a, b) => {
    return b.orderRank - a.orderRank;
  });

const dataOrdenadaOtros = data
  .filter((miembro) => miembro.orderRank === undefined)
  .sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

const dataFinal = dataOrdenadaPrincipales.concat(dataOrdenadaOtros);

export default dataFinal;
