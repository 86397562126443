import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import GeneralData from '../../generalData';
import router from '../../router';
import LogoCepap from './../../../img/logo-cpap-simple.png';

function CursoCpapList(props) {
  const [open, setOpen] = useState(false);

  const { name, shortDescription, imgPathSm, credits, dateStart, endDate } =
    props;

  function showMore() {
    setOpen(!open);
  }

  return (
    <Link to={router.cursoGet(name)}>
      <div className="shadow-sm  hover:shadow-xl border border-solid border-primary-10 overflow-hidden rounded-lg flex items-center relative">
        <div className="overflow-hidden  relative h-[100px]  w-[120px] lg:w-[180px] flex-none">
          <img
            src={`${GeneralData.aplicationPublicPath}${imgPathSm}`}
            className="object-cover  h-[100px]  w-[120px] lg:w-[180px]"
            alt=""
          />
          <div className="absolute  overflow-hidden inline-block bottom-0 right-0  w-full h-full">
            <div className="absolute bottom-0 right-0  w-1/3 z-10">
              <img src={LogoCepap} alt="" />
            </div>
            <div className="w-full h-full bg-primary-700 bg-opacity-50 clip-triangle-rectangle"></div>
          </div>
        </div>
        <div className=" h-full bg-white    flex  gap-1 px-4 py-1 flex-col ">
          <div className="flex flex-col items-start gap-1 ">
            {credits && (
              <span className="text-primary-400 text-xs bg-primary-100 px-1 font-bold rounded">
                {credits} créditos
              </span>
            )}
            <h3
              className={`leading-3 text-xs lg:text-sm font-bold ${GeneralData.style.layout.text.dark}`}
            >
              {name}
            </h3>

            <div className="flex gap-2 items-center">
              <span
                className={`text-xs cursor-pointer ${GeneralData.style.layout.text.link} font-bold`}
              >
                Más información
              </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CursoCpapList;
