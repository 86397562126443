import NavLink from './../../helpers/NavLink';
import Router from './../../../router';
import GeneralData from './../../../generalData';
import LogoFing from '../../../../img/logo-fing-rectangular.png';

import { FaBars } from 'react-icons/fa';

import ModalComponent from '../../helpers/ModalComponent';

const Header = (props) => (
  <header className="w-full px-8 text-gray-700 bg-primary-50 bg-opacity-50">
    <div className="container flex flex-col flex-wrap items-center justify-between py-2 mx-auto md:flex-row max-w-7xl">
      <div className="w-full flex flex-row items-center justify-between ">
        <div className="flex flex-row items-center md:border-r py-3 pr-8 md:border-gray-200">
          <NavLink
            exact={true}
            to={Router.home}
            className="flex items-center  font-medium text-primary-900 lg:w-auto lg:items-center lg:justify-center md:mb-0"
            activeClassName="text-primary-500 "
          >
            <div className="mr-4">
              <img width={`120`} src={LogoFing} />
            </div>
          </NavLink>
          <NavLink
            exact={true}
            to={Router.home}
            className="flex items-center  font-medium text-primary-900 lg:w-auto lg:items-center lg:justify-center md:mb-0"
            activeClassName="text-primary-500 "
          >
            <span className="mx-auto text-xl font-black  text-gray-900 select-none">
              GrIS<span className="text-indigo-600"></span>
            </span>
          </NavLink>
        </div>

        <nav className="flex flex-wrap items-center mb-5 text-base md:mb-0  hidden md:block  ">
          {GeneralData.menu.map((data) => (
            <NavLink
              key={data.name}
              exact={true}
              to={data.route}
              activeClassName={GeneralData.style.header.menu.listItemActive}
              className={GeneralData.style.header.menu.listItem}
            >
              {data.name}
            </NavLink>
          ))}
        </nav>
        <nav className="flex flex-wrap items-center  text-3xl md:mb-0   md:hidden block">
          <ModalComponent
            modalContent={
              <div className="flex flex-col items-center  space-y-6">
                {GeneralData.menu.map((data) => (
                  <NavLink
                    key={data.name}
                    exact={true}
                    to={data.route}
                    activeClassName={
                      GeneralData.style.header.menu.listItemActive
                    }
                    className={GeneralData.style.header.menu.listItem}
                  >
                    {data.name}
                  </NavLink>
                ))}
              </div>
            }
            activaModal={
              <div
                className={`${GeneralData.style.layout.text.grey} ${GeneralData.style.layout.text.primaryHover} cursor-pointer`}
              >
                <FaBars />
              </div>
            }
          />
        </nav>
      </div>
    </div>
  </header>
);

export default Header;
