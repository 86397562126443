import Router from './router';

import { FaLinkedin, FaTwitter } from 'react-icons/fa';

import UserSinFoto from './../img/helpers/sinFotoUser.jpg';

const generalData = {
  aplicationPublicPath: process.env.PUBLIC_URL,
  name: 'Grupo de ingeniería de software (GRIS)',
  shortDescription:
    'El Grupo de Ingeniería de Software (GRIS) es un grupo del INCO que realiza investigación en temas relativos a la Ingeniería de Software, principalmente en: Pruebas de Software (de forma empírica), Procesos de Desarrollo de Software y su Mejora, y Educación en Ingeniería de Software. /n/n El GRIS también colabora con la Industria de Software nacional asesorando sobre procesos de desarrollo y mantenimiento de software, mejora de dichos procesos, gestión de servicios de TI, mejora de técnicas de desarrollo de software y verificación, procesos de pruebas de software, entre otros.',
  contact: {
    email: 'gris@fing.edu.uy',
    phone: '',
    cellphone: '',
    cellphone2: '',
    address: '',
    business_hours: '',
  },
  socialMedia: [
    {
      url: 'https://twitter.com/gris_udelar',
      icon: <FaTwitter />,
    },
    {
      url: 'https://www.linkedin.com/company/82897398/',
      icon: <FaLinkedin />,
    },
  ],
  generalUserImg: UserSinFoto,
  noImagen: UserSinFoto,
  path_url: '',
  analitic_propiedad: '',
  set_analitic: function (titulo, path) {
    //gtag("js", new Date());
    //gtag("config", this.analitic_propiedad, {
    //page_title: titulo,
    //page_path: path,
    //  });
  },
  menu: [
    {
      name: 'Inicio',
      route: Router.home,
    },
    {
      name: 'Investigación',
      route: Router.investigacion,
    },
    {
      name: 'Enseñanza',
      route: Router.ensenanza,
    },
    {
      name: 'Actividades',
      route: Router.actividades,
    },
    {
      name: 'Sobre el GrIS',
      route: Router.about,
    },
    {
      name: 'Contacto',
      route: Router.contacto,
    },
  ],

  style: {
    cardsAnimationsHover: `transform transition duration-500 ease-in-out hover:-translate-y-1 hover:scale-105`,
    layout: {
      divideYGeneral: `divide-y divide-gray-50`,
      button: function (type, size) {
        let className = '';
        if (type === 'primary') {
          className =
            'flex items-center cursor-pointer px-6 py-3 text-lg text-white bg-primary-500 rounded-md hover:bg-primary-800 hover:text-white';
        } else if (type === 'secondary') {
          className =
            'flex items-center cursor-pointer px-6 py-3  text-lg text-gray-500 bg-gray-100 rounded-md hover:bg-gray-200 hover:text-gray-600';
        }

        return className;
      },
      text: {
        primary: `text-primary-500`,
        primaryLight: `text-primary-300`,
        primaryHover: `hover:text-primary-300`,
        dark: `text-primary-800`,
        darkLight: `text-primary-600`,
        grey: `text-gray-400`,
        greyLight: `text-gray-200`,
        success: `text-green-500`,
        link: `text-primary-300 hover:text-primary-200 cursor-pointer`,
      },
      bg: {
        light: `bg-primary-50`,
      },
    },
    header: {
      menu: {
        listItem:
          'py-3 px-4 font-medium leading-6 text-gray-500 hover:text-primary-300 rounded-md',
        listItemActive: 'text-primary-300 bg-gray-100',
      },
    },
    footer: {
      menu: {
        listItem:
          'py-2 px-4 font-base leading-6 text-gray-500 hover:text-primary-300 rounded-md',
        listItemActive: 'text-primary-300 bg-gray-100',
      },
    },
    pages: {
      firstSectionContainer: `px-2 py-16  lg:py-18 bg-white md:px-0  `,
      firstSectionTitle: `w-full text-4xl font-bold tracking-normal text-center text-primary-800  lg:text-4xl xl:text-5xl`,
      otherSectionTitle: `w-full text-4xl font-bold tracking-normal text-center text-primary-800  lg:text-4xl xl:text-5xl`,
    },
  },
};

export default generalData;
