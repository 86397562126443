import GeneralData from './../../../generalData';

const BasicSection = (props) => {
  const { children, bgHighlighted, fullWidth, smallPadding } = props;

  return (
    <section
      className={`py-16  ${smallPadding ? 'md:py-8' : 'md:py-28'}  ${
        fullWidth ? 'w-full' : 'container mx-auto'
      }  ${bgHighlighted ? 'bg-primary-50' : ''} flex flex-col items-center `}
    >
      <div
        className={`flex container max-w-6xl space-y-10 px-2 flex-col items-center ${
          bgHighlighted ? 'bg-primary-50' : ''
        } `}
      >
        {children}
      </div>
    </section>
  );
};

export default BasicSection;
