const data = [
  {
    title: 'Technical Debt ¿Y por casa cómo andamos?',
    shortDescription: `Resumen: La deuda técnica (DT) está recibiendo cada vez más atención en la Ingeniería de Software. Aunque inicialmente se usó como una herramienta de comunicación entre los interesados técnicos y no técnicos, hoy en día este concepto apoya de forma sustancial la mejora de la calidad interna del software. En esta presentación hablaremos de lo que es la DT, sus diferentes tipos y actividades para gestionarla. También presentaremos los resultados de una encuesta (realizada desde la Facultad de Ingeniería de UdelaR), sobre la percepción y grado de gestión de la DT en las empresas de software del Uruguay, en la cual participaron 396 profesionales del software.

    El conocimiento sobre qué es la deuda técnica y el ser conscientes de los riesgos y desafíos que conlleva su gestión resulta en una herramienta muy importante para los profesionales del software de hoy en día. Existen muchos escenarios en la industria del software en donde se posterga la calidad interna del software y "malas" decisiones técnicas son tomadas para favorecer una rápida salida al mercado y dar valor al cliente de forma continua. Si bien estas decisiones pueden representar una "inversión" en el corto plazo, pueden resultar en un gran problema en el largo plazo si la deuda técnica acumulada no es debidamente tratada y gestionada.
    
    `,
    place: '29 Evento GeneXus',
    clientName: '',
    isUyType: '',
    participants: ['Cecilia Apa'],
    dateStart: '2019-09-23', // respetar formato de fecha
    dateEnd: '', // respetar formato de fecha
    img: ['https://i.ytimg.com/vi/fcb90m2phMc/maxresdefault.jpg'],
    urlMoreInformation: '',
    youTubeVideoId: 'fcb90m2phMc',
  },
  {
    title:
      'Alternativas para el análisis cualitativo en ingeniería de software: análisis de contenido y análisis temático.',
    shortDescription: `En esta charla se introducen dos métodos de análisis cualitativos: análisis de contenido y análisis temático. Además se presentan ejemplos de aplicación en ingeniería de software y discuten las particularidades de su aplicación en esa disciplina.`,
    place: 'Seminario del GrIS - InCo - UdelaR',
    clientName: '',
    isUyType: '',
    participants: ['Sebastián Pizard'],
    dateStart: '2021-06-23', // respetar formato de fecha
    dateEnd: '', // respetar formato de fecha
    img: ['https://i.ytimg.com/vi/U2YfxpcQCEI/maxresdefault.jpg'],
    textMoreInformation: 'Material y video en sitio externo',
    urlMoreInformation:
      'https://drive.google.com/drive/folders/1LQnCZjoMspMZkH4filcBnInRQ5c4Kjrc?usp=sharing',
    youTubeVideoId: '',
  },
  {
    title: '¡Al Infinito y más allá!',
    shortDescription: `Primeras Jornadas en Ingeniería de Software del Uruguay (JIS.uy), 2018 Charla: Impulso: ¡Al Infinito y más allá!`,
    place: 'JIS.uy 2018',
    clientName: '',
    isUyType: 'jis.uy',
    participants: ['Diego Vallespir'],
    dateStart: '2018-10-23', // respetar formato de fecha
    dateEnd: '', // respetar formato de fecha
    img: [
      'https://i.ytimg.com/vi/QYBqRZqdFuY/hqdefault.jpg?sqp=-oaymwEcCPYBEIoBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBrsllW6aZYP7Ln9IshNY-HS07vHA',
    ],
    urlMoreInformation: '',
    youTubeVideoId: 'QYBqRZqdFuY',
  },
];

export default data;
