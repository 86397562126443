import { Link } from 'react-router-dom';
import GeneralData from '../../../generalData';

const Component = (props) => {
  const data = props.data;
  return (
    <Link to={data.to}>
      <div
        className={` bg-white rounded-lg border border-gray-200 shadow-md hover:shadow-lg flex flex-col overflow-hidden ${GeneralData.style.cardsAnimationsHover}`}
      >
        <div className="relative">
          <div className="absolute t-0 l-0 w-full h-full  bg-primary-500 bg-opacity-20 flex items-center justify-center "></div>

          <img
            className="rounded-t-lg object-cover w-full"
            src={data.urlImgSm}
            alt=""
          />
        </div>

        <div className="p-5 w-full flex flex-col justify-between ">
          <Link to={data.to}>
            <h5
              className={`mb-2 text-xl font-bold tracking-tight ${GeneralData.style.layout.text.dark}`}
            >
              {data.titulo}
            </h5>
          </Link>
          <p
            className={`mb-3 font-normal  ${GeneralData.style.layout.text.darkLight}`}
          >
            {data.shortDescription}
          </p>
          <Link
            to={data.to}
            className={`${GeneralData.style.layout.button(
              'primary',
              'normal'
            )} text-base px-4`}
          >
            {data.callToActionText}
            <svg
              className="ml-2 -mr-1 w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </Link>
        </div>
      </div>
    </Link>
  );
};

export default Component;
