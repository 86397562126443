import { useEffect } from 'react';
import Layout from './../layoutComponents/layout';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import Publicaciones from './../../../data/publicaciones';
import Publication from './../../components/models/Publication';
import integrantes from './../../../data/miembros';
import lineasDeInvestifacion from './../../../data/lineasDeInvestifacion';
import LineaInvestigacion from '../../components/models/LineaInvestigacion';
import FirstSection from '../layoutComponents/sections/FirstSection';
import BasicSection from '../layoutComponents/sections/BasicSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);

  const INTEGRANTES = integrantes;
  const LINEAS = lineasDeInvestifacion;

  const PUBLICAIONES = Publicaciones.sort(function (a, b) {
    return parseInt(b.info.year) - parseInt(a.info.year);
  }).filter(function (pub) {
    let teacherName = INTEGRANTES.map(function (teacher) {
      return teacher.name.toLowerCase();
    });

    let articleNames = pub.info.authors.author.map(function (person) {
      return person.text.toLowerCase();
    });
    let filteredArray = articleNames.filter((value) =>
      teacherName.includes(value)
    );

    return filteredArray.length > 0;
  });

  return (
    <Layout>
      <>
        <FirstSection
          titleContent={<span className="block xl:inline">Investigación</span>}
        />

        <BasicSection smallPadding>
          <>
            <div>
              <div
                className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
              >
                Algunas de las líneas de investigación que trabajamos en el GrIS
                son:
              </div>
              <div className="w-full grid grid-cols-1 gap-4">
                {LINEAS.map(function (linea) {
                  return <LineaInvestigacion data={linea} />;
                })}
              </div>
            </div>
          </>
        </BasicSection>
        {PUBLICAIONES.length > 0 && (
          <BasicSection smallPadding fullWidth bgHighlighted>
            <div>
              {' '}
              <div
                className={`text-xl w-full mb-4 font-bold ${GeneralData.style.layout.text.dark}`}
              >
                Publicaciones
              </div>
              <div className="w-full grid grid-cols-1 gap-4">
                <ol className=" relative border-l border-gray-200">
                  {PUBLICAIONES.map((data) => (
                    <div className="w-full">
                      <Publication key={data.info.title} data={data} />
                    </div>
                  ))}
                </ol>
              </div>
            </div>
          </BasicSection>
        )}
      </>
    </Layout>
  );
}

export default App;
