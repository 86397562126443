import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './../layoutComponents/layout';
import Router from './../../router';
import validURL from './../../validUrl';
import Miembros from './../../../data/miembros';
import CharlasYSeminarios from './../../../data/seminarios';
import SeminarioContent from './seminarios/seminarioContent';

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let name = useParams().name.toLowerCase().replace('-', ' ');

  let CHARLA = CharlasYSeminarios.filter(function (charla) {
    return (
      charla.title
        .toLocaleLowerCase()
        .replace(validURL, '-')
        .replace('-', ' ') === name
    );
  });

  let existe = CHARLA.length > 0;

  if (existe) {
    CHARLA = CHARLA[0];
  } else {
    alert('no existe integrante');
  }

  const Techers = Miembros.filter(function (miembro) {
    let EnMinuscula = CHARLA.participants.map(function (person) {
      return person.toLowerCase();
    });
    return EnMinuscula.includes(miembro.name.toLowerCase());
  });

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Actividades', route: Router.actividades },
        { name: CHARLA.title, route: '' },
      ]}
    >
      <>
        <SeminarioContent Techers={Techers} CHARLA={CHARLA} />
      </>
    </Layout>
  );
}

export default App;
