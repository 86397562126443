import React, { useState } from 'react';
import GeneralData from '../../generalData';
import Router from '../../router';
import { Link } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

import Miembros from '../../../data/miembros';

function Tutorias(props) {
  const [open, setOpen] = useState(false);

  const Data = props.data;

  const dateStart = Data.dateStart;

  const dateEnd = Data.dateEnd;

  const Techers = Miembros.filter(function (miembro) {
    let EnMinuscula = Data.teachers.map(function (teacher) {
      return teacher.toLowerCase();
    });
    return EnMinuscula.includes(miembro.name.toLowerCase());
  });

  function showMore() {
    setOpen(!open);
  }

  return (
    <div className="w-full bg-white shadow hover:shadow-xl rounded-xl p-5 ">
      <div className="w-full flex flex-row items-center mb-2 space-x-2 ">
        <div
          className={`text-sm ${
            Data.type == 'grado'
              ? GeneralData.style.layout.text.primary
              : GeneralData.style.layout.text.success
          }`}
        >
          Tesis de {Data.type}
        </div>

        <span className={`text-sm ${GeneralData.style.layout.text.greyLight}`}>
          |
        </span>

        <div className={`text-sm font-bold flex items-center gap-2 `}>
          {' '}
          {Data.status === 'finalized'
            ? 'Finalizada'
            : Data.status === 'open'
            ? 'Propuesta abierta'
            : 'En curso'}
          {Data.status === 'finalized' && <FaCheckCircle />}
        </div>
      </div>

      {(Data.status === 'finalized' || Data.status === 'in progress') && (
        <>
          <div className="w-full flex flex-row items-center mb-2 space-x-2 ">
            <span className="text-sm">Inicio: {dateStart}</span>

            {Data.status === 'finalized' && (
              <>
                <span
                  className={`text-sm ${GeneralData.style.layout.text.greyLight}`}
                >
                  |
                </span>
                <span className="text-sm">Fin: {dateEnd}</span>
              </>
            )}
          </div>
        </>
      )}
      <h3
        className={`mb-1  text-2xl font-bold ${GeneralData.style.layout.text.dark}`}
      >
        {Data.title}
      </h3>
      <p
        className={`text-base font-normal ${GeneralData.style.layout.text.darkLight}`}
      >
        {Data.shortDescription}
      </p>

      {Techers.length > 0 && (
        <>
          <div className="gap-2 flex flex-row items-center mt-3">
            <div className="text-sm font-bold">Tutor/a</div>
            <div className="flex flex-row items-center gap-3">
              {Techers.map((team) => (
                <Link to={Router.integranteGet(team.name)}>
                  <img
                    src={
                      team.img.length > 0
                        ? process.env.PUBLIC_URL + team.img[0]
                        : GeneralData.generalUserImg
                    }
                    alt=""
                    className="flex-none w-8 h-8 rounded-full object-cover"
                    loading="lazy"
                  />
                </Link>
              ))}
            </div>
          </div>
        </>
      )}

      {Data.students.length > 0 && (
        <>
          <div className="gap-2 flex flex-row items-center mt-3">
            <div className="text-sm font-bold">Estudiantes</div>
            <div className="flex flex-row items-center gap-2 ">
              {Data.students.map((student) => (
                <div className="bg-gray-100 rounded-lg p-1 text-sm">
                  {student}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Tutorias;
