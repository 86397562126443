import React, { useState } from 'react';
import Text from './Text';
import generalData from '../../generalData';

function TextWithShowMore(props) {
  const [showMore, setShowMore] = useState(false);
  const { text, showMoreIfLengthMayorThan } = props;

  const length = text.length;

  return (
    <>
      <Text
        text={`${text.slice(
          0,
          showMore ? length : showMoreIfLengthMayorThan
        )} ${length > showMoreIfLengthMayorThan && !showMore ? ' ...' : ''}`}
      />

      {length > showMoreIfLengthMayorThan && (
        <span>
          {showMore ? (
            <span
              className={generalData.style.layout.text.link}
              onClick={() => {
                setShowMore(false);
              }}
            >
              Ver menos
            </span>
          ) : (
            <>
              <span
                className={generalData.style.layout.text.link}
                onClick={() => {
                  setShowMore(true);
                }}
              >
                Ampliar
              </span>
            </>
          )}
        </span>
      )}
    </>
  );
}

export default TextWithShowMore;
