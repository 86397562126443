import React, { useRef, useState } from 'react';
import generalData from './../../../generalData';
import Text from '../../helpers/Text';
import { useInViewport } from 'react-in-viewport';
import { CSSTransition } from 'react-transition-group';
import { FaChevronDown } from 'react-icons/fa';

function TituloSeccionComponent(props) {
  const { titulo, seccion, titleSmall, isDropDown, dropDownInitValue } = props;
  const [show, setShow] = useState(isDropDown ? dropDownInitValue : true);
  const myRef = useRef();
  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef,
    {},
    { disconnectOnLeave: false },
    props
  );

  let seccionIsString = false;
  if (typeof seccion === 'string') {
    seccionIsString = true;
  }

  return (
    <CSSTransition
      in={inViewport}
      timeout={3000}
      classNames={{
        appear: 'opacity-0',
        appearActive: 'transition-opacity duration-1000 opacity-100',
        enter: 'opacity-0',
        enterActive: 'transition-opacity duration-1000 opacity-100',
        exitActive: 'transition-opacity duration-200 opacity-0',
      }}
    >
      <div ref={myRef} className="flex flex-col gap-2">
        <div className="w-full relative">
          <h3
            className={` ${titleSmall ? 'text-sm' : 'text-xl'} font-bold ${
              generalData.style.layout.text.dark
            }`}
          >
            {titulo}
          </h3>
          {isDropDown && (
            <div
              onClick={() => {
                setShow(!show);
              }}
              className="text-primary-600 cursor-pointer absolute right-[8px] top-1/2 p-4 rounded-full bg-primary-50 hover:bg-primary-100 transform -translate-y-1/2"
            >
              <FaChevronDown className={`${show && 'rotate-180'}`} />{' '}
            </div>
          )}
        </div>

        {show && (
          <div
            className={`text-sm whitespace-pre-line ${generalData.style.layout.text.dark} `}
          >
            {seccionIsString ? <Text text={seccion} /> : seccion}
          </div>
        )}
      </div>
    </CSSTransition>
  );
}

export default TituloSeccionComponent;
