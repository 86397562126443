import { Link } from 'react-router-dom';
import GeneralData from './../../../generalData';
import Router from './../../../router';
import YouTubeVideo from './../../helpers/VideoYotube';

function seminarioContent({ Techers, CHARLA }) {
  const options = { year: 'numeric', month: 'long' };

  const dateStart = new Date(CHARLA.dateStart).toLocaleDateString(
    'es-ES',
    options
  );

  const autoresTexto = CHARLA.participants.join(', ');

  return (
    <section className={`${GeneralData.style.pages.firstSectionContainer}`}>
      <div className="container items-center max-w-4xl px-8 mx-auto xl:px-5 ">
        <div className="flex flex-col items-center space-y-6">
          <div className="space-y-2">
            <h1
              className={`text-xl lg:text-3xl xl:text-4xl ${GeneralData.style.pages.firstSectionTitle} `}
            >
              <span className="block xl:inline"> {CHARLA.title} </span>
            </h1>
            <div
              className={`text-md text-center space-x-2 ${GeneralData.style.layout.text.grey}`}
            >
              <span>{dateStart} </span>
              <span>|</span>
              <span>Oradores: {autoresTexto} </span>
              <span>|</span>
              <span>Lugar : {CHARLA.place}</span>
            </div>
          </div>

          <div className="space-x-2 flex flex-row items-center ">
            {Techers.length > 0 && (
              <div className="flex flex-row items-center space-x-2 ">
                <div className="flex flex-row items-center space-x-2">
                  {Techers.map((team) => (
                    <Link to={Router.integranteGet(team.name)}>
                      <img
                        src={
                          team.img.length > 0
                            ? process.env.PUBLIC_URL + team.img[0]
                            : GeneralData.generalUserImg
                        }
                        alt=""
                        className="flex-none w-10 h-10 rounded-full object-cover"
                        loading="lazy"
                      />
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="p-3 lg:p-12 rounded-xl shadow flex flex-col gap-6">
            {CHARLA.youTubeVideoId !== '' ? (
              <div className="w-full p-5 relative">
                <YouTubeVideo video_id={CHARLA.youTubeVideoId} autoplay={0} />
              </div>
            ) : (
              <img
                src={
                  CHARLA.img.length > 0 ? CHARLA.img[0] : GeneralData.noImagen
                }
              />
            )}

            <div
              className={`text-base whitespace-pre-line  ${GeneralData.style.layout.text.grey} `}
            >
              {CHARLA.shortDescription}
            </div>

            {CHARLA.urlMoreInformation !== '' && (
              <a
                className={` text-xl  ${GeneralData.style.layout.text.primaryLight}   `}
                href={CHARLA.urlMoreInformation}
                target="_blanck"
              >
                {CHARLA.textMoreInformation
                  ? CHARLA.textMoreInformation
                  : 'Ir al sitio externo'}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default seminarioContent;
