import React, { useRef, useEffect } from 'react';

import Modal from './Modal';

const Modalcomponent = (props) => {
  const modal = useRef(null);
  const Contenido = () => <div className="p-5">{props.modalContent}</div>;
  useEffect(() => {
    if (props.open) {
      modal.current.open();
    }
  }, [props.open]);

  return (
    <span className="">
      <div className="w-100" onClick={() => modal.current.open()}>
        {props.activaModal}
      </div>
      <Modal ref={modal}>{<Contenido />}</Modal>
    </span>
  );
};

export default Modalcomponent;
