import { Link } from 'react-router-dom';
import Router from '../../router';
import Header from './header/Header';
import Footer from './footer/Footer';
import GeneralData from './../../generalData';

const Layout = (props) => (
  <div className={`w-full ${GeneralData.style.layout.divideYGeneral}`}>
    <Header />

    {props.showBreadcrumb === true && (
      <div className={`container mx-auto max-w-7xl `}>
        <nav
          className={`flex py-3 px-5 ${GeneralData.style.layout.text.grey} rounded-lg `}
          aria-label="Breadcrumb"
        >
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
              <Link
                to={Router.home}
                className={`inline-flex items-center text-sm font-medium ${GeneralData.style.layout.text.grey} ${GeneralData.style.layout.text.primaryHover}`}
              >
                <svg
                  className="mr-2 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                Home
              </Link>
            </li>
            {props.showBreadcrumbData.map(function (value, index) {
              return (
                <li key={`li-layout-${index}`}>
                  <div className="flex items-center">
                    <svg
                      className={`w-6 h-6 ${GeneralData.style.layout.text.greyLight}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    {props.showBreadcrumbData.length - 1 === index ? (
                      <span
                        className={`ml-1 text-sm font-medium ${GeneralData.style.layout.text.greyLight} md:ml-2 `}
                      >
                        {value.name}
                      </span>
                    ) : (
                      <Link
                        to={value.route}
                        className={`ml-1 text-sm font-medium ${GeneralData.style.layout.text.grey} ${GeneralData.style.layout.text.primaryHover} md:ml-2 `}
                      >
                        {value.name}
                      </Link>
                    )}
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
    )}

    <main
      className={`w-full ${props.customClasses ? props.customClasses : ''}`}
    >
      {props.children}
    </main>

    <Footer />
  </div>
);

export default Layout;
