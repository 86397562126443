import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './../layoutComponents/layout';
import Router from './../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import Miembros from './../../../data/miembros';
import Publicaciones from '../../../data/publicaciones';
import Publication from '../../components/models/Publication';
import TutoriasYPropuestas from '../../../data/propuestaDeTesisYTutorias';
import Tesis from '../../components/models/TesisTutorias';

import {
  FaAddressCard,
  FaLinkedin,
  FaTwitter,
  FaGlobe,
  FaResearchgate,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa';

function Integrante(props) {
  let name = useParams().name.toLowerCase().replaceAll('-', ' ');

  let INTEGRANTE = Miembros.filter(function (miembro) {
    return miembro.name.toLowerCase() === name;
  });

  let existe = INTEGRANTE.length > 0;

  if (existe) {
    INTEGRANTE = INTEGRANTE[0];
  } else {
    //alert("no existe integrante");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    InitPageHelper.PageInitHelper({
      title: INTEGRANTE.name,
      description: INTEGRANTE.shortDescription,
    });
  }, [INTEGRANTE.name]);

  let PUBLICACIONES = Publicaciones.filter(function (publicacion) {
    const checkByName = publicacion.info.authors.author
      .map(function (person) {
        return person.text.toLowerCase();
      })
      .includes(INTEGRANTE.name.toLowerCase());

    if (INTEGRANTE.pid) {
      const checkByPid = publicacion.info.authors.author
        .map(function (person) {
          return person['@pid'];
        })
        .includes(INTEGRANTE.pid);

      return checkByName || checkByPid;
    }

    return checkByName;
  }).sort(function (a, b) {
    return parseInt(b.info.year) - parseInt(a.info.year);
  });

  let TUTORIAS = TutoriasYPropuestas.filter(function (tutoria) {
    let teacersName = tutoria.teachers.map(function (teac) {
      return teac.toLowerCase();
    });
    return teacersName.includes(INTEGRANTE.name.toLowerCase());
  });

  return (
    <Layout
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Sobre el GrIS', route: Router.about },
        { name: INTEGRANTE.name, route: '' },
      ]}
    >
      <>
        <section className={`${GeneralData.style.pages.firstSectionContainer}`}>
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="flex flex-col items-center space-y-6">
              <img
                src={
                  INTEGRANTE.img.length > 0
                    ? process.env.PUBLIC_URL + INTEGRANTE.img[0]
                    : 'https://www.stignatius.co.uk/wp-content/uploads/2020/10/default-user-icon.jpg'
                }
                alt=""
                className="flex-none w-40 h-40 rounded-full object-cover"
                loading="lazy"
              />

              <div className="flex  flex-row items-center space-x-2 mb-3">
                {INTEGRANTE.linkedin !== '' && (
                  <a
                    className={`${GeneralData.style.layout.text.primary} text-3xl `}
                    href={INTEGRANTE.linkedin}
                  >
                    <FaLinkedin />
                  </a>
                )}
                {INTEGRANTE.researchgate !== '' && (
                  <a
                    className={`${GeneralData.style.layout.text.primary} text-3xl `}
                    href={INTEGRANTE.researchgate}
                  >
                    <FaResearchgate />
                  </a>
                )}
                {INTEGRANTE.cvuy !== '' && (
                  <a
                    className={`${GeneralData.style.layout.text.primary} text-3xl `}
                    href={INTEGRANTE.cvuy}
                  >
                    <FaAddressCard />
                  </a>
                )}
                {INTEGRANTE.web !== '' && (
                  <a
                    className={`${GeneralData.style.layout.text.primary} text-3xl `}
                    href={INTEGRANTE.web}
                  >
                    <FaGlobe />
                  </a>
                )}
                {INTEGRANTE.twitter !== '' && (
                  <a
                    className={`${GeneralData.style.layout.text.primary} text-3xl `}
                    href={INTEGRANTE.twitter}
                  >
                    <FaTwitter />
                  </a>
                )}
              </div>

              <h1 className={`${GeneralData.style.pages.firstSectionTitle}`}>
                <span className="block xl:inline"> {INTEGRANTE.name} </span>
              </h1>

              <div
                className={`text-base text-center ${GeneralData.style.layout.text.grey} `}
              >
                {INTEGRANTE.shortDescription}
              </div>

              <div className="flex flex-col bg-slate-100 shadow  rounded-xl p-6 w-full space-y-4 ">
                <div>
                  <div
                    className={`text-xl font-bold  ${GeneralData.style.layout.text.dark} mb-2`}
                  >
                    Información
                  </div>
                  <div
                    className={`w-full mb-5  pl-2 space-y-1 ${GeneralData.style.layout.text.grey}`}
                  >
                    <div className="text-sm">
                      Grado:{' '}
                      {INTEGRANTE.rankDescription
                        ? INTEGRANTE.rankDescription
                        : INTEGRANTE.rank}
                    </div>
                    <div className="text-sm">
                      Rol:{' '}
                      {INTEGRANTE.typeDescription
                        ? INTEGRANTE.typeDescription
                        : INTEGRANTE.status == 'inactive'
                        ? 'ex-integrante'
                        : INTEGRANTE.type}
                    </div>

                    {INTEGRANTE.email !== '' && (
                      <div className="text-sm">
                        <FaEnvelope className="inline" /> {INTEGRANTE.email}
                      </div>
                    )}

                    {INTEGRANTE.phone !== '' && (
                      <div className="text-sm">
                        <FaPhone className="inline" /> {INTEGRANTE.phone}
                      </div>
                    )}
                  </div>
                </div>

                {PUBLICACIONES.length > 0 && (
                  <div>
                    <div
                      className={`text-xl font-bold  ${GeneralData.style.layout.text.dark} mb-2`}
                    >
                      Publicaciones
                    </div>

                    <ol className=" relative border-l border-gray-200">
                      {PUBLICACIONES.map(function (publication) {
                        return <Publication data={publication} />;
                      })}
                    </ol>
                  </div>
                )}

                {TUTORIAS.length > 0 && (
                  <div>
                    <div
                      className={`text-xl font-bold  ${GeneralData.style.layout.text.dark} mb-2`}
                    >
                      Tutorías realizadas, en curso o abiertas.
                    </div>
                    <div className="w-full grid grid-cols-1 gap-4 ">
                      {TUTORIAS.map((data) => (
                        <div className="w-full">
                          <Tesis key={data.title} data={data} />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
}

export default Integrante;
