import { useEffect } from 'react';

import { Link } from 'react-router-dom';
import Layout from './../layoutComponents/layout';
import Router from './../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import GeneralData from './../../generalData';
import { FaEnvelope } from 'react-icons/fa';
import ContactoImg from './../../../img/draws/contacto.png';
import FirstSection from '../layoutComponents/sections/FirstSection';

function App(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  InitPageHelper.PageInitHelper(props.pageData);
  return (
    <Layout>
      <>
        <FirstSection
          titleContent={
            <>
              <span className="block xl:inline">Contactar al </span>
              {` `}
              <span className="block text-primary-500 xl:inline">GrIS</span>
            </>
          }
        />
        <section>
          <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
            <div className="flex flex-col items-center sm:-mx-3 gap-12 ">
              <div className="flex items-center justify-center flex-wrap gap-16 ">
                <div className="shadow-sm  rounded-xl border border-gray-50">
                  <img
                    src={ContactoImg}
                    className="max-w-[400px]"
                    alt="GrIS contacto"
                  />
                </div>

                <ul className="max-w-[300px] flex flex-col gap-6 ">
                  <li className="text-sm shadow-sm p-3 rounded-xl border border-gray-200">
                    <span
                      className={`${GeneralData.style.layout.text.primary} `}
                    >
                      <FaEnvelope className="inline text-2xl" />{' '}
                      {GeneralData.contact.email}
                    </span>
                  </li>

                  <li className="text-sm shadow-sm p-3 rounded-xl border border-gray-200">
                    Contactar directamente a algún integrante del grupo.{' '}
                    <Link
                      to={Router.integrantes}
                      className={`${GeneralData.style.layout.text.primary} ${GeneralData.style.layout.text.primaryHover}`}
                    >
                      Ir a la página de integrantes
                    </Link>
                  </li>
                  <li>
                    <span className="flex  items-center gap-2 text-sm shadow-sm p-3 rounded-xl border border-gray-200">
                      {GeneralData.socialMedia.map(function (social) {
                        return (
                          <div>
                            <a
                              className={`${GeneralData.style.layout.text.primary} ${GeneralData.style.layout.text.primaryHover} text-2xl`}
                              href={social.url}
                            >
                              {social.icon}
                            </a>
                          </div>
                        );
                      })}
                      <span>Redes sociales</span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </>
    </Layout>
  );
}

export default App;
