import GeneralData from '../../../generalData';

const FirstSection = (props) => {
  const { titleContent, descriptionContent } = props;
  return (
    <section className={`${GeneralData.style.pages.firstSectionContainer}`}>
      <div className="container items-center max-w-6xl px-8 mx-auto xl:px-5">
        <div className="flex flex-col items-center sm:-mx-3 space-y-10">
          <h1 className={` ${GeneralData.style.pages.firstSectionTitle}`}>
            {titleContent}
          </h1>
          {descriptionContent && (
            <div
              className={`text-lg text-center ${GeneralData.style.layout.text.grey} w-full lg:w-3/4 space-y-10`}
            >
              {descriptionContent}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default FirstSection;
