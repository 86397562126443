import React, { useState } from 'react';
import GeneralData from '../../generalData';
import Miembros from '../../../data/miembros';
import Router from '../../router';
import { Link } from 'react-router-dom';

function Componente(props) {
  const [open, setOpen] = useState(false);

  const Data = props.data;

  const options = { year: 'numeric', month: 'long' };

  const autoresTexto = Data.participants.join(', ');

  const Techers = Miembros.filter(function (miembro) {
    let EnMinuscula = Data.participants.map(function (person) {
      return person.toLowerCase();
    });
    return EnMinuscula.includes(miembro.name.toLowerCase());
  });

  function showMore() {
    setOpen(!open);
  }

  return (
    <li className=" shadow bg-white  rounded-xl p-4 space-y-2">
      {Data.img.length > 0 && (
        <div className="flex flex-col items-start">
          <div className="p-2 bg-white rounded-2xl h-[80px]">
            <img
              src={process.env.PUBLIC_URL + Data.img[0]}
              alt=""
              className="h-full"
            />
          </div>
        </div>
      )}

      <h3 className={`text-lg font-bold ${GeneralData.style.layout.text.dark}`}>
        {Data.title}
      </h3>

      {Data.shortDescription !== '' && <div>{Data.shortDescription}</div>}

      <p
        className={`text-base mt-2 font-normal ${GeneralData.style.layout.text.darkLight} mb-2`}
      >
        {Data.place !== '' && (
          <span>
            Lugar: <strong>{Data.place}</strong>
          </span>
        )}
      </p>
      <time className=" space-x-3 text-sm font-normal leading-none text-gray-400 ">
        <span className="font-bold"> Inicio: {Data.dateStart}</span>{' '}
        {Data.dateEnd !== '' && <span>Fin: {Data.dateEnd}</span>}
      </time>
      {Techers.length > 0 && (
        <>
          <div className="space-x-2 flex flex-row items-center mt-3">
            <div className="flex flex-row items-center space-x-2">
              {Techers.map((team) => (
                <Link to={Router.integranteGet(team.name)}>
                  <img
                    src={
                      team.img.length > 0
                        ? process.env.PUBLIC_URL + team.img[0]
                        : GeneralData.generalUserImg
                    }
                    alt=""
                    className="flex-none w-6 h-6 rounded-full object-cover"
                    loading="lazy"
                  />
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
      <p
        className={` ${GeneralData.style.layout.text.darkLight} `}
        onClick={showMore}
      >
        {open ? (
          <span>Participantes: {autoresTexto}</span>
        ) : (
          <>
            <span>Participantes: </span>
            <span className="">
              {` ${autoresTexto.slice(0, 80)} `}{' '}
              {autoresTexto.length > 80 ? '...' : ''}
            </span>
          </>
        )}
      </p>

      {Data.urlMoreInformation !== '' && (
        <a
          href={Data.urlMoreInformation}
          className={`inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 ${GeneralData.style.layout.text.primaryHover} focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700  `}
        >
          Leer más{' '}
          <svg
            className="ml-2 w-3 h-3"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </a>
      )}
    </li>
  );
}

export default Componente;
