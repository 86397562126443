import React, { useState } from 'react';
import GeneralData from './../../../generalData';
import { FaChalkboardTeacher, FaPlus } from 'react-icons/fa';
import useCurso from './../../composables/use-curso';
import LogoCepap from './../../../../img/logo-cpap.png';
import useWindowSize from '../../composables/use-window-size';
import TituloSeccionComponent from './TituloSeccionComponent';
import Text from '../../helpers/Text';
import TeamBoxForCarrerAndCourses from '../../layoutComponents/team/TeamBoxForCarrerAndCourses';
import LineSeparator from '../../helpers/LineSeparator';
import generalData from './../../../generalData';
import TextWithShowMore from '../../helpers/TextWithShowMore';
import SectionBlock from './SectionBlock';
import useMember from '../../composables/use-member';

function CursoPageComponent(props) {
  const [open, setOpen] = useState(false);
  const { parseDate, canISignUp } = useCurso();

  const { width } = useWindowSize();

  const {
    name,
    longDescription,
    imgPathSm: imgPath,
    imgPathLg,
    credits,
    dateStart,
    endDate,
    goals,
    targetAudiences,
    teachers,
    schedules,
    inscriptionUrl,
    topics,
    certificateAndAwards,
    scholarshipsAndDiscounts,
  } = props;

  const { getTeachersAll } = useMember();

  const teachersTeam = getTeachersAll(teachers);

  return (
    <SectionBlock>
      <div className="flex flex-col lg:flex-row lg:h-[350px]  overflow-hidden border-b border-solid border-primary-100">
        <div className="order-2 lg:order-1 lg:w-1/2 p-6 lg:p-12 flex flex-col items-start justify-center gap-8 lg:gap-16">
          <div className="flex flex-col gap-2 items-start">
            <div className="flex items-center gap-2">
              <div
                className={`flex items-center gap-1 rounded text-base text-primary-400 p-1 font-bold bg-primary-50`}
              >
                <FaChalkboardTeacher className="inline" /> Curso de posgrado
              </div>
              {credits && (
                <div
                  className={`flex items-center gap-1 rounded text-base text-primary-400 p-1 font-bold bg-primary-50`}
                >
                  <FaPlus className="inline" /> {credits} créditos
                </div>
              )}
            </div>
            <h1
              className={`text-4xl font-bold ${GeneralData.style.layout.text.dark}`}
            >
              {name}
            </h1>
          </div>

          <div className="flex flex-col gap-2 items-start">
            <div className="flex flex-wrap gap-2 items-start">
              {inscriptionUrl && (
                <a
                  href={inscriptionUrl}
                  className={`${
                    !canISignUp(dateStart)
                      ? 'opacity-50 pointer-events-none'
                      : ''
                  }`}
                >
                  <button
                    className={`${GeneralData.style.layout.button('primary')} `}
                  >
                    Inscribirme
                  </button>
                </a>
              )}
            </div>

            {!canISignUp(dateStart) && (
              <div className={`text-xs ${GeneralData.style.layout.text.grey}`}>
                {' '}
                En breve habilitaremos las inscripciones.{' '}
              </div>
            )}
          </div>
        </div>
        <div className="order-1 lg:order-2 lg:h-full lg:w-1/2 ">
          <div className="overflow-hidden relative h-full">
            <img
              className="object-cover h-full w-full"
              src={`${GeneralData.aplicationPublicPath}${
                width > 600 ? imgPathLg : imgPath
              }`}
              alt=""
            />
            <div className="absolute  overflow-hidden inline-block bottom-0 right-0  w-full h-full">
              <div className="absolute top-0 right-0  w-1/3 z-10">
                <img src={LogoCepap} alt="" />
              </div>
              <div className="w-full h-full bg-primary-700 bg-opacity-70 clip-triangle-rectangle"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col lg:flex-row">
        <div className="p-6 lg:p-12 bg-primary-100 lg:max-w-[300px] flex-none flex flex-col gap-4">
          {dateStart && (
            <TituloSeccionComponent
              titulo="Inicio de curso"
              titleSmall
              seccion={parseDate(dateStart)}
            />
          )}

          {endDate && (
            <TituloSeccionComponent
              titulo="Fin del curso"
              titleSmall
              seccion={parseDate(endDate)}
            />
          )}

          {schedules && (
            <TituloSeccionComponent
              titulo="Horarios"
              titleSmall
              seccion={<Text text={schedules} />}
            />
          )}

          {credits && (
            <TituloSeccionComponent
              titulo="Créditos"
              titleSmall
              seccion={credits}
            />
          )}

          {inscriptionUrl && (
            <a
              href={inscriptionUrl}
              className={`text-sm ${generalData.style.layout.text.link} ${
                !canISignUp(dateStart) ? 'opacity-50 pointer-events-none' : ''
              }`}
            >
              Inscribirme a este curso
            </a>
          )}
        </div>
        <div className="p-6 lg:p-12 ">
          {longDescription && (
            <TituloSeccionComponent
              titulo="Descripción"
              seccion={
                <TextWithShowMore
                  text={longDescription}
                  showMoreIfLengthMayorThan={300}
                ></TextWithShowMore>
              }
            />
          )}

          {targetAudiences && (
            <>
              <LineSeparator />
              <TituloSeccionComponent
                titulo="Dirigido a:"
                seccion={
                  <TextWithShowMore
                    text={targetAudiences}
                    showMoreIfLengthMayorThan={500}
                  ></TextWithShowMore>
                }
              />
            </>
          )}

          {goals && (
            <>
              <LineSeparator />

              <TituloSeccionComponent
                titulo="Objetivos"
                seccion={
                  <TextWithShowMore
                    text={goals}
                    showMoreIfLengthMayorThan={500}
                  ></TextWithShowMore>
                }
              />
            </>
          )}

          {topics && (
            <>
              <LineSeparator />
              <TituloSeccionComponent
                isDropDown
                dropDownInitValue={false}
                titulo="Temario"
                seccion={
                  <ul className="pl-4 marker:text-primary-300 list-disc list-outside">
                    {topics.map((topic) => (
                      <li key={`topic-${topic.title}`} className="">
                        <div className="p-1  flex flex-col gap-1 ">
                          <h4 className="">
                            {' '}
                            <strong>{topic.title}</strong>{' '}
                          </h4>
                          {topic.description && (
                            <p className="text-xs">{topic.description}</p>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                }
              />
            </>
          )}

          {teachers && (
            <>
              <LineSeparator />
              <TituloSeccionComponent
                titulo="Equipo docente"
                seccion={
                  <div className=" gap-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                    <TeamBoxForCarrerAndCourses teacersObjects={teachersTeam} />
                  </div>
                }
              ></TituloSeccionComponent>
            </>
          )}

          {certificateAndAwards && (
            <>
              <LineSeparator />
              <TituloSeccionComponent
                titulo="Certificado y reconocimientos"
                seccion={certificateAndAwards}
              />
            </>
          )}

          {scholarshipsAndDiscounts && (
            <>
              <LineSeparator />
              <TituloSeccionComponent
                titulo="Becas y descuentos"
                seccion={scholarshipsAndDiscounts}
              />
            </>
          )}

          {inscriptionUrl && (
            <>
              <LineSeparator />
              <a
                href={inscriptionUrl}
                className={`${
                  !canISignUp(dateStart) ? 'opacity-50 pointer-events-none' : ''
                }`}
              >
                <button
                  className={`${GeneralData.style.layout.button(
                    'primary'
                  )} text-sm p-1 `}
                >
                  Inscribirme a este curso
                </button>
              </a>
            </>
          )}
        </div>
      </div>
    </SectionBlock>
  );
}

export default CursoPageComponent;
