const setDescription = (desc) => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute('content', desc);
};

const helper = {
  PageInitHelper: function (dataPage) {
    if (dataPage != null) {
      document.title = dataPage.title;
      setDescription(dataPage.description);
    }
  },
};

export default helper;
