import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Text(props) {
  const { text } = props;

  return (
    <ReactMarkdown remarkPlugins={[remarkGfm]} className="prose">
      {text}
    </ReactMarkdown>
  );
}

export default Text;
