import React, { useState } from 'react';
import GeneralData from '../../../generalData';
import useCurso from '../../composables/use-curso';

import useWindowSize from '../../composables/use-window-size';
import TituloSeccionComponent from '../cursos/TituloSeccionComponent';
import TextWithShowMore from '../../helpers/TextWithShowMore';
import CursoCpapList from '../../models/CursoCpapList';
import { FaGraduationCap } from 'react-icons/fa';
import SectionBlock from '../cursos/SectionBlock';

function PlanPageComponent(props) {
  const { width } = useWindowSize();

  const { getCareerCoursesFromIds } = useCurso();

  const { name, coursesIds, plan, planSubjects, planDownloadUrl } = props;

  const courses = getCareerCoursesFromIds(coursesIds);

  const coursesOptionals = courses.filter((course) => course.optional);

  const [showSubjects, setShowSubjects] = useState(false);

  return (
    <div className="w-full flex flex-col gap-8">
      <SectionBlock>
        <div className="flex flex-col lg:flex-row lg:h-[200px]  overflow-hidden border-b border-solid border-primary-100">
          <div className=" p-6 lg:p-12 flex flex-col items-start justify-center gap-8 lg:gap-0">
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <div
                  className={`flex items-center gap-1 rounded text-base text-primary-400 p-1 font-bold bg-primary-50`}
                >
                  <FaGraduationCap className="inline" /> {name}
                </div>
              </div>
              <h1
                className={`text-4xl font-bold ${GeneralData.style.layout.text.dark}`}
              >
                Plan de estudio y cursos{' '}
              </h1>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col lg:flex-row">
          <div className="p-6 lg:p-12  w-full">
            {plan && (
              <TituloSeccionComponent
                titulo="Plan"
                seccion={
                  <div className="flex flex-col gap-8">
                    <TextWithShowMore
                      text={plan}
                      showMoreIfLengthMayorThan={800}
                    ></TextWithShowMore>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </SectionBlock>

      <SectionBlock>
        <div className="p-6 lg:p-12  w-full">
          {courses && (
            <div className="flex flex-col gap-16">
              <TituloSeccionComponent
                titulo="Cursos obligatorios"
                seccion={
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-2 ">
                    {courses.map((course) => (
                      <div className="grow" key={course.id}>
                        <CursoCpapList key={course.id} {...course} />
                      </div>
                    ))}
                  </div>
                }
              />
              {coursesOptionals.length > 0 && (
                <TituloSeccionComponent
                  titulo="Cursos opcionales"
                  seccion={
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-2 ">
                      {coursesOptionals.map((course) => (
                        <div className="grow" key={course.id}>
                          <CursoCpapList {...course} />
                        </div>
                      ))}
                    </div>
                  }
                />
              )}
            </div>
          )}
        </div>
      </SectionBlock>

      <SectionBlock>
        <div className="p-6 lg:p-12  w-full ">
          <TituloSeccionComponent
            seccion={
              <iframe
                src="https://calendar.google.com/calendar/embed?src=ujeb35dmpu482401t9i4fifcpk%40group.calendar.google.com&ctz=America%2FMontevideo"
                width="100%"
                height="400"
              ></iframe>
            }
          />
        </div>
      </SectionBlock>

      {planDownloadUrl && (
        <SectionBlock>
          <div className="p-6 lg:p-12  w-full ">
            <TituloSeccionComponent
              titulo="Programa"
              seccion={
                <a href={planDownloadUrl}>
                  Especialización en Ingeniería de Software (PDF)
                </a>
              }
            />
          </div>
        </SectionBlock>
      )}
    </div>
  );
}

export default PlanPageComponent;
