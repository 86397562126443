const data = [
  {
    title: 'Introducción a la Ingeniería de Software',
    shortDescription:
      'Este curso busca brinda un panorama general de la ingeniería de software profundizando en los aspectos más relevantes. Introduce al estudiante en el uso de algunas técnicas y herramientas particulares para que pueda participar en distintos roles en un proyecto de ingeniería de software.',

    grado: true,
    posgrado: false,
    dateStart: '2022-05-31', // respetar formato de fecha
    dateEnd: '2022-07-14', // respetar formato de fecha
    img: [
      'https://media-exp1.licdn.com/dms/image/C5603AQF6Fg7fHi8euw/profile-displayphoto-shrink_400_400/0/1516319847899?e=1658966400&v=beta&t=bmsYWC7R1fQf7guPiVy_fIgyrYg-31kmywMcexy7da4',
    ],
    urlMoreInformation: 'https://eva.fing.edu.uy/course/view.php?id=613',
  },
  {
    title: 'Principios y fundamentos del Proceso Personal de Software',
    shortDescription:
      'Este curso tiene como objetivo brindar los conceptos básicos del proceso personal de software (personal software process - PSP). Particularmente, se focaliza en la calidad de software y la importancia de la disciplina en el proceso de desarrollo de software.',

    grado: true,
    posgrado: false,
    dateStart: '2022-05-31', // respetar formato de fecha
    dateEnd: '2022-07-14', // respetar formato de fecha
    img: [
      'https://media-exp1.licdn.com/dms/image/C5603AQF6Fg7fHi8euw/profile-displayphoto-shrink_400_400/0/1516319847899?e=1658966400&v=beta&t=bmsYWC7R1fQf7guPiVy_fIgyrYg-31kmywMcexy7da4',
    ],
    urlMoreInformation:
      'https://eva.fing.edu.uy/course/view.php?id=633#section-0',
  },
  {
    title: 'Ingeniería de Software Empírica',
    shortDescription:
      'Este curso busca que el estudiante conozca y comprenda la aplicación de diversos métodos de investigación en la ingeniería de software. En particular, se presenta el método científico, el proceso de investigación y sus elementos básicos, y diversos métodos de investigación aplicados a la ingeniería de software.',

    grado: true,
    posgrado: true,
    dateStart: '2022-05-31', // respetar formato de fecha
    dateEnd: '2022-07-14', // respetar formato de fecha
    img: [
      'https://media-exp1.licdn.com/dms/image/C5603AQF6Fg7fHi8euw/profile-displayphoto-shrink_400_400/0/1516319847899?e=1658966400&v=beta&t=bmsYWC7R1fQf7guPiVy_fIgyrYg-31kmywMcexy7da4',
    ],
    urlMoreInformation: 'https://eva.fing.edu.uy/course/view.php?id=941',
    isCpapCourse: false,
    isPedecibaCourse: true,
  },
  {
    title: 'Proyecto de Ingeniería de Software',
    shortDescription:
      'Este curso tiene como objetivo afirmar y profundizar los conocimientos de ingeniería de software, contrastarlos con su aplicación práctica e integrarlos con conocimientos de otras disciplinas de la computación, entre las que se destacan, sin ser exhaustivo: programación, arquitectura, sistemas operativos y redes de computadores, bases de datos y sistemas de información.',

    grado: true,
    posgrado: false,
    dateStart: '2022-05-31', // respetar formato de fecha
    dateEnd: '2022-07-14', // respetar formato de fecha
    img: [
      'https://media-exp1.licdn.com/dms/image/C5603AQF6Fg7fHi8euw/profile-displayphoto-shrink_400_400/0/1516319847899?e=1658966400&v=beta&t=bmsYWC7R1fQf7guPiVy_fIgyrYg-31kmywMcexy7da4',
    ],
    urlMoreInformation: 'https://eva.fing.edu.uy/enrol/index.php?id=573',
    extraInfo: {
      text: 'Llamado a propuesta de proyectos',
      url: 'https://eva.fing.edu.uy/enrol/index.php?id=573',
    },
  },
  {
    title: 'Ingeniería de Software basada en Evidencia',
    shortDescription:
      'Este curso busca brinda una visión general y práctica de ingeniería de software basada en evidencias (EBSE), describiendo sus fundamentos, técnicas, y herramientas para su aplicación práctica. EBSE pretende mejorar la toma de decisiones relacionada al desarrollo y mantenimiento de software integrando la mejor evidencia actual de la investigación con experiencias prácticas.',

    grado: true,
    posgrado: true,
    dateStart: '2022-05-31', // respetar formato de fecha
    dateEnd: '2022-07-14', // respetar formato de fecha
    img: [
      'https://media-exp1.licdn.com/dms/image/C5603AQF6Fg7fHi8euw/profile-displayphoto-shrink_400_400/0/1516319847899?e=1658966400&v=beta&t=bmsYWC7R1fQf7guPiVy_fIgyrYg-31kmywMcexy7da4',
    ],
    urlMoreInformation: 'https://eva.fing.edu.uy/course/view.php?id=1086',
    isCpapCourse: false,
    isPedecibaCourse: false,
  },
  {
    title: 'Profesión en Ingeniería de Software',
    shortDescription:
      'Este curso tiene como objetivo brindar un panorama general sobre la profesión en ingeniería de software. Introduce al estudiante al cuerpo de conocimiento de la ingeniería de software (SWEBOK), a los aspectos que definien la profesión y conocer los elementos que la definen (educación, sociedades profesionales, desarrollo de habilidades, etc.).',

    grado: true,
    posgrado: false,
    dateStart: '2022-05-31', // respetar formato de fecha
    dateEnd: '2022-07-14', // respetar formato de fecha
    img: [
      'https://media-exp1.licdn.com/dms/image/C5603AQF6Fg7fHi8euw/profile-displayphoto-shrink_400_400/0/1516319847899?e=1658966400&v=beta&t=bmsYWC7R1fQf7guPiVy_fIgyrYg-31kmywMcexy7da4',
    ],
    urlMoreInformation:
      'https://www.fing.edu.uy/curso/grado/2018/profesion-en-ingenieria-de-software',
  },
];

export default data;
