import { CONTACT_INFO } from './../../../data/cpapGeneralnfo';

const contactInfo = CONTACT_INFO;

export default function useCpapData() {
  const getContactInfo = () => {
    return contactInfo;
  };

  return {
    getContactInfo,
    contactInfo,
  };
}
