const data = [
  {
    title: 'PSPCode Tool: PSP support tool',
    shortDescription:
      'Este proyecto de grado construyó una herramienta de  soporte web para el curso PF-PSP que permite la recolección y registro de datos, la corrección de las entregas y la configuración del curso por parte de los docentes.',
    type: 'grado', // grado | posgrado
    programa: 'Ingeniería en computación',
    status: 'finalized', //  open |  finalized | in progress
    dateStart: '2017', // respetar formato de fecha
    dateEnd: '2018', // respetar formato de fecha
    img: [],
    teachers: ['Silvana Moreno'],
    students: ['Guillermo Kuster', 'Guillermo Tavidian'],
  },
  {
    title: 'Tool:extension to the PSP support tool',
    shortDescription:
      'Este trabajo de grado tiene como objetivo ampliar la herramienta PSP Code, generando correcciones automáticas, integrándose con la herramienta estadística R, y permitiendo adecuar procesos, entre otros.',
    type: 'grado', // grado | posgrado
    programa: 'Ingeniería en computación',
    status: 'in progress', //  open |  finalized | in progress
    dateStart: '2022', // respetar formato de fecha
    dateEnd: null, // respetar formato de fecha
    img: [],
    teachers: ['Silvana Moreno'],
    students: ['Lía Malvarez', 'Gustavo Samir'],
  },
  {
    title:
      'Uso de la ingeniería de software basada en evidencias en la industria local',
    shortDescription:
      'El objetivo fue utilizar EBSE para contribuir en la resolución de un problema real de la industria local del software. En particular, diagnosticamos ciertos problemas de gestión del conocimiento y documentación de la empresa con la que trabajamos. Luego, planificamos y ejecutamos una revisión sistemática de literatura simplificada y finalmente transferimos el conocimiento obtenido a la empresa.',
    type: 'grado', // grado | posgrado
    programa: 'Ingeniería en computación',
    status: 'finalized', //  open |  finalized | in progress
    dateStart: '2019', // respetar formato de fecha
    dateEnd: '2020', // respetar formato de fecha
    img: [],
    teachers: ['Sebastián Pizard', 'Diego Vallespir'],
    students: ['Joaquín Lezama', 'Rodrigo García'],
  },
];

export default data;
