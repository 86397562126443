export default [
  {
    id: 'INGENIERIA_DE_REQUISITOS', // must be unique
    name: 'Ingeniería de Requisitos',
    shortDescription: '',
    longDescription:
      'En este curso se presenta el área de conocimiento “Ingeniería de Requisitos” en sus aspectos más esenciales. Profundiza sobre el conocimiento teórico y práctico necesario para una adecuada gestión de los requisitos durante el ciclo de vida de un producto de software. \n\n Durante el curso se brindarán consejos y guías prácticas para mejorar la práctica en la disciplina.',
    imgPathSm: '/images/cpap/cursos/INGENIERIA_DE_REQUISITOS-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/INGENIERIA_DE_REQUISITOS-lg.jpg',
    credits: '5',
    dateStart: '2024-03-11',
    endDate: '2024-04-18',
    price: 24000,
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [
      { name: 'María de las Nieves Freira', isResponsable: true },
      { name: 'Inés Lamas' },
    ],
    goals:
      'Al finalizar el curso esperamos que el estudiante adquiera conocimiento teórico y práctico sólido acerca de la disciplina de requisitos, permitiéndole discutir y tomar decisiones sobre aspectos esenciales y polémicos que condicionan el éxito de los proyectos.',
    targetAudiences:
      'Estudiantes de posgrado o profesionales informáticos interesados en ingeniería de requisitos. Integrantes de equipo de desarrollo o mantenimiento de software. Líderes e integrantes de equipos de analistas.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=31845',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Ninguno.',
    knowledgeNiceToHave: 'Conocimientos básicos de ingeniería de software.',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Introducción a la Ingeniería de requisitos',
        description: 'Conceptos generales y buenas prácticas.',
      },
      {
        title: 'El proceso de desarrollo de los requisitos',
        description:
          'Establecer la visión del producto y del cliente. Análisis del cliente. Técnicas para la obtención, análisis, especificación y validación de los requisitos.',
      },
      {
        title: 'Gestión de los requisitos',
        description:
          'Principios y prácticas de gestión de los requisitos, como por ejemplo: línea base de requisitos, procedimientos para la gestión de los requisitos, control de versiones, entre otros. Gestión del cambio, trazabilidad, reutilización y herramientas para la gestión de los requisitos. Requisitos en proyectos con metodologías ágiles.',
      },
      {
        title: 'Requisitos para proyectos especiales',
        description:
          'Requisitos para proyectos de mejora y reemplazo, productos off-the-shelf, proyectos de outsourcing, proyectos de automatización de proyectos, proyectos de business analytics, entre otros.',
      },
    ],
  },
  {
    id: 'TALLER_PLANIFICACION_SOFTWARE', // must be unique
    name: 'Taller de planificación y seguimiento de proyectos de software',
    shortDescription: '',
    longDescription:
      'La planificación y el seguimiento de los proyectos de software son actividades esenciales dentro de la gestión de proyectos de software. La ejecución eficaz de estas actividades es uno de los factores determinantes en el éxito de los proyectos. Este curso presenta conceptos y técnicas necesarias para llevar adelante estas actividades, en distintos modelos de proceso, tanto basados en planes como en procesos ágiles. Tiene un fuerte componente práctico, porque procura que el estudiante sea capaz de reconocer y aplicar diferentes técnicas con solvencia, a fin de que pueda elegir la que considere más adecuada a las diferentes situaciones que se presenten en su vida profesional.',
    imgPathSm: '/images/cpap/cursos/TALLER_PLANIFICACION_SOFTWARE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/TALLER_PLANIFICACION_SOFTWARE-lg.jpg',
    credits: '5',
    dateStart: '',
    endDate: '',
    price: '',
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [{ name: 'María de las Nieves Freira', isResponsable: true }],
    goals:
      'Los objetivos del curso son que el estudiante aprenda los conceptos y domine las técnicas necesarias para llevar adelante la planificación y el seguimiento de un proyecto de software, tanto en procesos basados en planes como en procesos ágiles.',
    targetAudiences:
      'Líderes de proyectos de software. Gerentes de proyectos de software. Profesionales interesados en la planificación y control de proyectos de software. Estudiantes de posgrado interesados en temas de gestión de proyectos de software, su planificación y control.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=34508',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Conocimientos básicos de Ingeniería de Software.',
    knowledgeNiceToHave: '',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Introducción',
        description: 'Visión. Factores que afectan la planificación.',
      },
      {
        title: 'Plan para la dirección del proyecto',
        description:
          'Contenidos. Estándares. Técnicas para el armado del plan.',
      },
      {
        title: 'Plan de la iteración',
        description: 'Coordinación entre los distintos planes',
      },
      {
        title: 'El proceso de planificación',
        description:
          'Priorización de requisitos. Calidad de la planificación. Modelo de evaluación.',
      },
      {
        title: 'Reflexiones',
        description:
          'Cuándo planificar. Los planes por períodos o por producto. Los requisitos de un buen plan. La frecuencia de la planificación. El mantenimiento del plan.',
      },
      {
        title: 'La gestión del alcance',
        description:
          'Alcance del producto y alcance del proyecto. ADV. WBS. Determinación del alcance (otros parámetros).',
      },
      {
        title: 'Secuenciar las actividades',
        description:
          'Grafo de actividades. Método de diagramación por precedencias (PDM).',
      },
      {
        title: 'Desarrollar el cronograma',
        description:
          'Técnicas para desarrollar el cronograma. Técnicas de optimización de recursos. Técnicas de modelado y análisis de datos. Características del software que inciden en la planificación y seguimiento. Técnicas de compresión del cronograma. Escenarios para desarrollar un plan. Time-boxing.',
      },
      {
        title: 'Controlar el cronograma. Registro y control de avance',
        description:
          'Técnicas de registro y control de avance del proyecto. Distinción entre avance real y percibido. Registro de esfuerzo. Análisis del esfuerzo. Técnicas de medición de avance de actividades.',
      },
      {
        title: 'Planificación y seguimiento del avance en procesos ágiles',
        description:
          'Manifiesto y principios ágiles. Factores relevantes para el grado de formalidad / agilidad conveniente. Principales procesos ágiles. Grado de prescripción / adaptabilidad. Principios básicos de distintos métodos ágiles. Scrum vs. Kanban. Planificación ágil. Planificación y seguimiento en procesos ágiles.',
      },
      {
        title: 'Herramientas',
        description:
          'Herramientas de modelado y diagramación. Herramientas de gestión de proyectos.',
      },
    ],
  },
  {
    id: 'EXPERIMENTANDO_METODOS_AGILES', // must be unique
    name: 'Experimentando con métodos ágiles de software',
    shortDescription: '',
    longDescription:
      'Este curso se enfoca en brindar conocimiento fundamental del paradigma ágil y algunos de los diferentes frameworks, métodos y técnicas compatibles con dicha filosofía. También se presenta cómo adaptarse a las diferentes situaciones y aprender de ellas para lograr adoptar estos métodos adecuadamente, esto significa hacerlo según los distintos contextos de empresas y organizaciones.',
    imgPathSm: '/images/cpap/cursos/EXPERIMENTANDO_METODOS_AGILES-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/EXPERIMENTANDO_METODOS_AGILES-lg.jpg',
    credits: '4',
    dateStart: '',
    endDate: '',
    price: '',
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [
      { name: 'Diego Vallespir', isResponsable: true },
      { name: 'Gabriel Ledesma' },
    ],
    goals:
      'El participante del curso quedará preparado para evaluar el contexto de un proyecto de software y comenzar a experimentar utilizando Scrum, XP, Lean Development, TSP, complementando uno y otro método según el contexto y las necesidades.',
    targetAudiences:
      'Destinado a quienes integran equipos de desarrollo de software y gestionan sus proyectos con métodos ágiles o quieren comenzar a gestionarlos de esta forma.',
    inscriptionUrl:
      'https://www.fing.edu.uy/index.php/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=39889',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired:
      'Conocimientos básicos de ingeniería de software y de desarrollo de software.',
    knowledgeNiceToHave: '',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Conceptos básicos de procesos de software',
        description: '',
      },
      {
        title: 'Introducción al paradigma ágil',
        description: '',
      },
      {
        title: 'El framework Scrum',
        description: '',
      },
      {
        title: 'Diferencias entre Scrum y eXtreme Programming',
        description: '',
      },
      {
        title: 'Planificación de un proyecto ágil',
        description: '',
      },
      {
        title: 'El método Lean para software',
        description: '',
      },
      {
        title: 'Team Software Process (TSP – Proceso de Software para Equipos)',
        description: '',
      },
      {
        title: 'Técnicas para un Product Owner (Dueño de Producto)',
        description: '',
      },
      {
        title: 'Evaluar a la organización para la adopción del paradigma ágil',
        description: '',
      },
      {
        title: 'Retrospectivas y mejora continua de procesos de software',
        description: '',
      },
    ],
  },
  {
    id: 'RELACIONES_PERSONALES_IS',
    name: 'Relaciones Personales en Ingeniería de Software',
    shortDescription: '',
    longDescription:
      'Este curso pretende introducir al estudiante en los aspectos y fundamentos básicos del relacionamiento dentro de los grupos de trabajo en ingeniería de software.',
    imgPathSm: '/images/cpap/cursos/RELACIONES_PERSONALES_IS-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/RELACIONES_PERSONALES_IS-lg.jpg',
    credits: '7',
    dateStart: '2023-05-31',
    endDate: '2023-10-31',
    price: 32200,
    schedules: 'Lunes, martes y jueves de 18:30 a 21:30 hs.',
    teachers: [
      { name: 'Laura González', isCoordinator: true },
      { name: 'Cecilia Apa', isResponsable: true },
      { name: 'Raquel Sosa' },
      { name: 'Bruno Rienzi' },
      { name: 'Gustavo Guimerans' },
      { name: 'Sebastián Vergara' },
      { name: 'Andrés Nebel' },
    ],
    goals:
      'Se espera que el estudiante adquiera habilidades blandas (no técnicas) enfocadas en mejorar el relacionamiento y comunicación con su equipo.',
    targetAudiences:
      'Estudiantes de posgrado y profesionales informàticos interesados en relaciones personales en ingeniera de software que tengan a su cargo equipos de trabajo.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=9773',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired:
      'El estudiante debe tener experiencia de trabajo en equipos de proyecto de desarrollo de software o de áreas donde sea fundamental el trabajo en equipo de al menos un año.',
    knowledgeNiceToHave: '',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Comunicación, trabajo en equipo y motivación',
        description:
          'El proceso de comunicación Fuentes de problemas en la comunicación. Técnicas y soluciones para los problemas en la comunicación. Resolución de conflictos. Manejo de lenguaje no verbal. Escucha Activa. Motivación. Taller de Comunicación.',
      },
      {
        title: 'Liderazgo y Delegación',
        description:
          'Definir: organización, dirección, jefe y líder. Enumerar diferentes tipos de liderazgo. Describir el perfil del líder eficaz. Reconocer en sí mismo atributos de liderazgo. El liderazgo basado en principios (Covey). Delegación.',
      },
      {
        title: 'Negociación',
        description:
          'Niveles de negociación: competitivo, cooperativo, colaborativo. El método Harvard de negociación ( Fisher y Ury ). Las tácticas y contratácticas. Taller de Negociación.',
      },
      {
        title: 'Trabajo en equipo',
        description:
          'La moda y el modo de trabajar en equipo. Características de un equipo exitoso. Las claves para fracasar en equipo. La función versus el rol. Los ciclos que atraviesa un equipo. Gestión del conocimiento en equipos de trabajo. Aprendizaje individual y en equipo. Los conflictos, los roles obstructores y como revertirlos. Los estilos personales y su impacto en los vínculos. La sinergia y la diversidad.',
      },
    ],
  },
  {
    id: 'PRUEBAS_SOFTWARE', // must be unique
    name: 'Técnicas y Gestión de las Pruebas de Software',
    shortDescription: '',
    longDescription:
      'En este curso se presentan conceptos, métodos, técnicas y procesos relativos a las múltiples actividades de las pruebas dentro del proceso de desarrollo de software, para mejorar la calidad de los productos generados y la eficiencia en su producción.',
    imgPathSm: '/images/cpap/cursos/PRUEBAS_SOFTWARE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/PRUEBAS_SOFTWARE-lg.jpg',
    credits: '6',
    dateStart: '',
    endDate: '',
    price: '',
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [
      { name: 'Diego Vallespir', isResponsable: true },
      { name: 'Cecilia Apa' },
      { name: 'Gustavo Guimerans' },
      { name: 'Darío Macchi' },
      { name: 'María Elisa Presto' },
    ],
    goals:
      'Se espera que los estudiantes adquieran conocimientos sobre técnicas, herramientas y procesos que les permitan definir estrategias de pruebas adecuadas a cada producto y proyecto de software, adaptándolas a cada contexto y situación particular. Además, se espera concientizar a los estudiantes sobre la importancia e impacto de una adecuada estrategia y gestión de las pruebas de software en la calidad de los productos de software y los costos asociados a su construcción.',
    targetAudiences:
      'Profesionales en informática y áreas afines interesados en Ingeniería de Software, en particular profesionales informáticos involucrados en el desarrollo, la calidad y las pruebas de software. Estudiantes de posgrado en informática y áreas afines interesados en el desarrollo, la calidad y las pruebas de software.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=48536',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Conocimientos generales de ingeniería de software. ',
    knowledgeNiceToHave: 'Experiencia en desarrollo y pruebas de software.',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title:
          'Introducción: elementos fundamentales de las pruebas de software',
        description:
          'Desarrollo y calidad de software. Pruebas y calidad de software. Principales roles y responsabilidades.',
      },
      {
        title: 'Procesos y actividades de las pruebas de software',
        description:
          'Planificación y control. Análisis y diseño. Implementación y ejecución. Evaluación de criterios de finalización de las pruebas e informes. Actividades de cierre.',
      },
      {
        title:
          'Introducción a las técnicas de pruebas y al diseño de casos de prueba',
        description:
          'Pruebas estáticas y revisiones. Pruebas dinámicas (funcional y estructural). Pruebas no funcionales. Pruebas funcionales (basadas en la especificación). Pruebas estructurales (basadas en la estructura del software).',
      },
      {
        title: 'Pruebas de acuerdo a las características del software',
        description:
          'Características de calidad del producto de software. Pruebas adaptadas a la característica (seguridad, usabilidad, rendimiento, entre otras).',
      },
      {
        title: 'Pruebas según el modelo de desarrollo de software',
        description:
          'Los 4 niveles de pruebas. Desarrollo y pruebas ágiles. Desarrollo y pruebas evolutivas/incrementales.',
      },
      {
        title: 'Gestión del proyecto de pruebas',
        description:
          'Introducción y responsabilidades. Objetivos de las pruebas. Estrategia y modelo de pruebas, gestión del riesgo. Métricas a ser utilizadas. Estimación de esfuerzo. Documentación y redacción del plan de pruebas. Gestión de pruebas y herramientas.',
      },
      {
        title: 'Gestión de incidentes',
        description:
          'El papel de la gestión de incidentes. Clasificación normalizada de incidentes. Herramientas de gestión de Incidentes.    ',
      },
      {
        title: 'Herramientas, automatización y ambientes de pruebas',
        description:
          'Introducción y papel de las herramientas de prueba. Tipos de herramientas de prueba. Introducción a la automatización. Ambientes y automatización para aplicaciones móviles.',
      },
      {
        title: 'Temas emergentes en pruebas de software',
        description: '',
      },
    ],
  },
  {
    id: 'LIDERANDO_EQUIPOS', // must be unique
    name: 'Liderando Equipos de Desarrollo de Software',
    shortDescription: '',
    longDescription:
      'En la actualidad el software es desarrollado por equipos de personas. Los proyectos llevados adelante por estos equipos deben ser gestionados cuantitativamente para lograr cumplir con el cronograma, con los costos previstos y con los requerimientos acordados. Durante el curso se presentará el Team Software Process y se introducirán conceptos relacionados a la gestión de procesos de forma cuantitativa, así como también los conocimientos y habilidades necesarias para liderar equipos de software de forma efectiva.',
    imgPathSm: '/images/cpap/cursos/LIDERANDO_EQUIPOS-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/LIDERANDO_EQUIPOS-lg.jpg',
    credits: '6',
    dateStart: '',
    endDate: '',
    price: '',
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [
      { name: 'Diego Vallespir', isResponsable: true },
      { name: 'Nadia Naya' },
      { name: 'Alexandra Castelli' },
    ],
    goals:
      'El estudiante adquirirá los conocimientos y las habilidades necesarias para liderar efectivamente un equipo de desarrollo de software. A su vez, será capaz de gestionar cuantitativamente proyectos de software usando medidas de calidad, costo y cronograma.',
    targetAudiences:
      'Líderes de equipos de desarrollo y/o mantenimiento de software. Integrantes de equipos de desarrollo y/o mantenimiento de software. Personas interesadas en procesos ágiles, híbridos y/o disciplinados. Estudiantes de posgrado interesados en el liderazgo de equipos de desarrollo de software, los procesos de desarrollo de software o la calidad de software.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=4645',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Conocimientos generales de ingeniería de software',
    knowledgeNiceToHave: '',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'El Personal Software Process (PSP)',
        description:
          'Introducción al PSP. Medición en el proceso de desarrollo. Estimación de software. Calidad de software. Diseño de software. Uso de los datos para planificar y seguir proyectos. Herramienta de recolección de datos. Investigación científica usando el PSP.',
      },
      {
        title: 'El líder del equipo',
        description:
          'El desafío de ser líder. Trabajo con conocimiento (knowledge work). Liderazgo. Motivando equipos.',
      },
      {
        title: 'Panorama general del Team Software Process (TSP)',
        description:
          'Introducción al TSP. Los roles. El rol del líder. El proceso. El entrenador (coach). Seguimiento y reporte a la gerencia.',
      },
      {
        title: 'Despegue del equipo (team lunch)',
        description:
          'Las reuniones de despegue y el desarrollo de un plan de proyecto. Las responsabilidades y rol del líder durante el despegue.',
      },
      {
        title: 'Disciplina de proceso',
        description:
          'Conceptos básicos sobre procesos. Medidas en el TSP. La importancia de la disciplina de procesos. Estableciendo la disciplina de procesos.',
      },
      {
        title: 'Liderando al equipo',
        description:
          'Gestionando trabajadores del conocimiento (knowlede workers). Ayudando a que los miembros del equipo se desarrollen.',
      },
      {
        title: 'Gestionando el plan',
        description:
          'Introducción. Indicadores de estado del calendario. Manteniendo los compromisos del equipo.',
      },
      {
        title: 'Gestionando la calidad',
        description:
          'Definición de calidad. Costos de la calidad. Técnicas de remoción de defectos. Medidas de calidad en el TSP. Planificando para lograr alta calidad. Evaluando la calidad durante el proyecto. ¿Qué puede hacer el líder del equipo?',
      },
      {
        title: 'Reportando a la gerencia',
        description:
          'El rol de la gerencia. Guías para el reporte del estado del proyecto. Gerente de un equipo de TSP.',
      },
      {
        title: 'Mejora continua en equipos del TSP',
        description:
          'Revisión de control. Postmortem de ciclo y proyecto. Propuestas de mejora de proceso.',
      },
    ],
  },
  {
    id: 'EVOLUCION_DE_SOFTWARE', // must be unique
    name: 'Evolución de Software',
    shortDescription: '',
    longDescription:
      'En este curso se profundizará en en los conceptos de la evolución y el mantenimiento de software. Se verán aspectos teóricos, ejemplos prácticos y reales de aplicación, buenas prácticas de la industria, introducción a los requisitos relacionados a esta disciplina en estándares y normas internacionales.',
    imgPathSm: '/images/cpap/cursos/EVOLUCION_DE_SOFTWARE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/EVOLUCION_DE_SOFTWARE-lg.jpg',
    credits: '6',
    dateStart: '2024-11-04',
    endDate: '2024-12-05',
    price: 28800,
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [
      { name: 'Jorge Triñanes', isResponsable: true },
      { name: 'Sebastián Pizard' },
      { name: 'Elisa Facio' },
    ],
    goals:
      'El estudiante aprenderá a analizar los problemas relacionados con la evolución y el mantenimiento de software desde las perspectivas técnica y del negocio, teniendo en cuenta las necesidades de corto y de largo plazo y considerando distintos aspectos, tales como: calidad, costo, esfuerzo y satisfacción de los interesados. Como parte del curso el alumno también conocerá los principales conceptos y prácticas relacionadas a la Gestión de la Configuración del Software.',
    targetAudiences:
      'Profesionales en informática, en particular integrantes de equipos de desarrollo y mantenimiento de software. Líderes de equipos de desarrollo y mantenimiento de software. Desarrolladores de software interesados en el mantenimiento y la evolución del software. Estudiantes de posgrado y áreas afines en el desarrollo, el mantenimiento y la evolución del software.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=34511',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Conocimientos generales de ingeniería de software',
    knowledgeNiceToHave: '',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Conceptos básicos de la evolución y mantenimiento de software',
        description: '',
      },
      {
        title: 'Marco general y fundamentos del cambio del software',
        description: '',
      },
      {
        title: 'Limitaciones e impacto económico del cambio del software',
        description: '',
      },
      {
        title: 'Comprensión de los programas',
        description: '',
      },
      {
        title: 'Refactoring y reestructuración',
        description: '',
      },
      {
        title: 'Ingeniería reversa',
        description: '',
      },
      {
        title: 'Gestión de la Configuración',
        description: '',
      },
      {
        title: 'Testing y su relación con Gestión de la Configuración',
        description: '',
      },
      {
        title: 'Gestión y organización',
        description: '',
      },
      {
        title: 'Documentación y conocimiento',
        description: '',
      },
      {
        title: 'Sistemas legados',
        description: '',
      },
      {
        title: 'Estrategias de liberación',
        description: '',
      },
      {
        title: 'Deuda técnica',
        description: '',
      },
      {
        title: 'Arquitectura y evolución del software',
        description: '',
      },
      {
        title: 'Integración, liberación y despliegue continuos, DevOps',
        description: '',
      },
      {
        title: 'Herramientas',
        description: '',
      },
      {
        title: 'Mediciones y uso de la información disponible',
        description: '',
      },
      {
        title: 'Modelos de evaluación y de mejora del mantenimiento',
        description: '',
      },
      {
        title: 'Procesos de evolución y mantenimiento',
        description: '',
      },
    ],
  },
  {
    id: 'TECNICAS_CALIDAD_SOFTWARE', // must be unique
    name: 'Técnicas de calidad de software para proyectos ágiles y tradicionales',
    shortDescription: '',
    longDescription:
      'En este curso se presentan temas de calidad de software y técnicas asociadas que se utilizan tanto en proyectos ágiles como tradicionales. Se tratan los siguientes temas: conceptos de calidad de software, técnicas de calidad de software, Inspección de software, mejora de procesos de software como una forma de abordar la mejora continua de la calidad, implantación (y adaptación) de procesos de desarrollo de software para lograr cambios organizacionales, pull request, TDD, programación de a pares y revisiones individuales. Durante el curso se realizan ejercicios prácticos y se desarrolla un laboratorio para que el profesional que asista al mismo pueda aplicar o proponer la aplicación de estas técnicas en su ambiente laboral.',
    imgPathSm: '/images/cpap/cursos/TECNICAS_CALIDAD_SOFTWARE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/TECNICAS_CALIDAD_SOFTWARE-lg.jpg',
    credits: '5',
    dateStart: '2024-09-30',
    endDate: '2024-10-31',
    price: 24000,
    schedules:
      'Lunes, martes y jueves de 18 a 21 hs. (a excepción de algunas clases que serán hasta 20.15 las cuales se avisarán con anticipación).',
    teachers: [
      { name: 'Carolina Valverde', isResponsable: true },
      { name: 'Silvana Moreno' },
      { name: 'Cecilia Apa' },
      { name: 'Darío Macchi' },
    ],
    goals:
      'El profesional que asista al curso mejorará su comprensión sobre la importancia de la calidad de software en diversos aspectos. Adquirirá conocimientos sólidos sobre ciertas técnicas de calidad de software y se espera que sea capaz de aplicar dichas técnicas y llevar adelante la implantación de cambios que mejoren la calidad con la que se produce software en un proyecto.',
    targetAudiences:
      'Líderes de equipos de desarrollo y/o mantenimiento de software. Líderes de equipos de aseguramiento de la calidad. Integrantes de equipos de aseguramiento de la calidad, de desarrollo y/o mantenimiento de software. Gerentes de proyectos de software. Estudiantes de posgrado interesados en las técnicas de calidad de software.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=32983',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Conocimientos básicos de ingeniería de software.',
    knowledgeNiceToHave: '',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Calidad de software',
        description:
          'Fundamentos de la calidad de software. Procesos de gestión de la calidad de software. Consideraciones prácticas.',
      },
      {
        title: 'Técnicas de calidad de software',
        description: '',
      },
      {
        title: 'Técnicas de lectura de artefactos de software',
        description: '',
      },
      {
        title:
          'Implantación de procesos de desarrollo de software y entrenamiento',
        description:
          'Implantación de procesos de desarrollo de software. Entrenamiento. Dificultades comunes y cómo evitarlas.',
      },
      {
        title: 'Calidad de software en proyectos ágiles',
        description: '',
      },
      {
        title: 'El proceso de Inspección de software',
        description: 'Inicio, documentación, revisión y conclusión.',
      },
      {
        title:
          'Mejora de procesos (basado en medidas de calidad de la Inspección)',
        description: '',
      },
    ],
  },
  {
    id: 'TALLER_TESTING_PERFORMANCE', // must be unique
    name: 'Taller de Testing de Performance',
    shortDescription: '',
    longDescription:
      'En este curso se profundizan los aspectos prácticos de las pruebas de performance con una modalidad taller.',
    imgPathSm: '/images/cpap/cursos/TALLER_TESTING_PERFORMANCE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/TALLER_TESTING_PERFORMANCE-lg.jpg',
    credits: '5',
    dateStart: '2024-07-15',
    endDate: '2024-08-15',
    price: 24000,
    schedules: 'Lunes, martes y jueves de 18:30 a 21:30 hs.',
    teachers: [
      { name: 'Laura González', isResponsable: true },
      { name: 'Gustavo Guimerans' },
      { name: 'Federico Orihuela' },
    ],
    goals:
      'Al finalizar el curso esperamos que el estudiante adquiera la capacidad de reflexionar sobre la necesidad de realizar las pruebas de performance y pueda gestionar y participar activamente proyectos de testing de performance. Además, se espera que adquiera habilidades en el diseño de estrategias de prueba combinando diversas técnicas de testing de performance en conjunto con la utilización de diferentes herramientas.',
    targetAudiences:
      'Estudiantes de posgrado o profesionales informáticos interesados en Testing de Performance.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=10239',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired: 'Conocimientos básicos de ingeniería de software.',
    knowledgeNiceToHave:
      'Se recomienda tener conocimientos de Testing de software, contar con experiencia en proyectos de desarrollo de software y conocimientos básicos de programación, bases de datos, redes y entornos Web.',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos',

    topics: [
      {
        title: 'Introducción a las pruebas de performance.',
        description: '',
      },
      {
        title: 'Visión general del Testing de Performance',
        description: '',
      },
      {
        title: 'Tipos de Pruebas de Performance',
        description: '',
      },
      {
        title: 'Arquitecturas y Testing de Performance',
        description: '',
      },
      {
        title: 'Etapas de un test de performance',
        description: '',
      },
      {
        title: 'Definición de las Pruebas (aplicado a un caso real)s',
        description: '',
      },
      {
        title: 'Preparación problemas clásicos de automatización',
        description:
          'Certificados, cookies, autenticación, códigos de respuesta, etc.',
      },
      {
        title: 'Protocolos de comunicación',
        description: 'Por ejemplo: HTTP/HTTPS, servicios',
      },
      {
        title: 'Automatización de las pruebas',
        description: 'automatizando una aplicación Web en laboratorio',
      },
      {
        title: 'Herramientas disponibles en el mercado',
        description: '',
      },
      {
        title: 'Armado del ambiente de prueba',
        description: '',
      },
      {
        title: 'Monitoreo, configuración y demo de herramientas',
        description: '',
      },
      {
        title: 'Ejecución de las pruebas',
        description: '',
      },
    ],
  },
  {
    id: 'COSTOS_PARA_INGENIERIA_DE_SOFTWARE', // must be unique
    name: 'Costos para Ingeniería de Software',
    shortDescription: '',
    longDescription:
      'Usualmente el profesional informático se vincula en los proyectos de software hasta la estimación de horas-hombre requeridas. Otras decisiones como períodos de actualización tecnológica, herramientas en las que invertir, proyectos a abordar, productos a discontinuar, contratar personal o hacer outsourcing usualmente son ajenas al personal informático. Este curso profundiza en herramientas y técnicas que apoyen a la toma de este tipo de decisiones en los proyectos.',
    imgPathSm: '/images/cpap/cursos/COSTOS_PARA_INGENIERIA_DE_SOFTWARE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/COSTOS_PARA_INGENIERIA_DE_SOFTWARE-lg.jpg',
    credits: '5',
    dateStart: '2024-04-23',
    endDate: '2024-05-23',
    price: 24000,
    schedules: 'Lunes, martes y jueves de 18:00 a 21:00 hs.',
    teachers: [{ name: 'Ariel Sabiguero', isCoordinator: true }],
    goals:
      'A través de este curso se introduce al profesional en el tema de la economía aplicada al software. Con las herramientas dadas en este curso, los participantes tendrán mejor capacidad para presentar sus ideas y proyectos, pudiendo incidir de forma efectiva en procesos de decisión.',
    targetAudiences:
      'Estudiantes de posgrado o profesionales informáticos interesados costos para ingeniería de software.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=5608',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired:
      'Conocimientos generales de ingeniería de software y matemáticas',
    knowledgeNiceToHave:
      'Experiencia o conocimientos en gestión de proyectos, economía y administración.',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Herramientas económicas para la toma de decisión',
        description: '',
      },
      {
        title: 'Toma de decisiones en organizaciones con fines de lucro',
        description: '',
      },
      {
        title:
          'Toma de decisiones en organizaciones sin fines de lucro y estado',
        description: '',
      },
      {
        title: 'Estimación, riesgo y manejo de incertidumbre',
        description: '',
      },
    ],
  },
  {
    id: 'ARQUITECTURA_DE_SOFTWARE', // must be unique
    name: 'Arquitectura de Software',
    shortDescription:
      'Este curso introduce los conceptos esenciales de la Arquitectura de Software haciendo énfasis en la importancia del negocio (o misión) en cuyo contexto son desarrollados los sistemas de software.',
    longDescription:
      'Presiones en los **tiempos de ejecución de un proyecto**, ausencia de métodos controlados para cumplir con los objetivos planteados, continuos cambios en las funcionalidades requeridas, documentación pobre o inexistente, falta de interlocutores válidos. Estos son solo algunos de los problemas que enfrenta hoy día el desarrollo de software. \n\n En la última década la Arquitectura de Software ha surgido como disciplina que intenta contrarrestar estos efectos negativos, ocupando cada vez más un rol significativo en la estrategia de negocio de una organización que basa su operativa en el software.  Esto se debe a que hacer foco en la Arquitectura de Software permite flexibilidad y adaptabilidad en mercados cambiantes, ofrece una herramienta de venta y comercialización, ayuda a reducir los costos de mantenimiento, amortiza los costos de desarrollo, ofrece una guía clara para el desarrollo favoreciendo el control de los proyectos, establece un vocabulario común entre los participantes, entre tantos otros aspectos de interés. \n\n Este curso introduce los conceptos esenciales de la Arquitectura de Software haciendo énfasis en la importancia del negocio (o misión) en cuyo contexto son desarrollados los sistemas de software.',
    imgPathSm: '/images/cpap/cursos/ARQUITECTURA_DE_SOFTWARE-sm.jpg', //
    imgPathLg: '/images/cpap/cursos/ARQUITECTURA_DE_SOFTWARE-lg.jpg',
    credits: '7',
    dateStart: '2024-05-28',
    endDate: '2024-07-04',
    price: 33600,
    schedules: 'Lunes, martes y jueves de 18:30 a 21:30 hs.',
    teachers: [
      { name: 'Gustavo Guimerans', isResponsable: true },
      { name: 'Raquel Sosa' },
      { name: 'Bruno Rienzi' },
      { name: 'Sebastián Vergara' },
      { name: 'Andrés Nebel' },
    ],
    goals:
      'Al terminar el curso esperamos que el estudiante fortalezca su capacidad para comprender, documentar, definir, evaluar y evolucionar una Arquitectura de Software.',
    targetAudiences:
      'Profesionales y estudiantes de posgrado interesados en profundizar en el área de Arquitectura de Software, en particular, en el marco de Sistemas de Información actuales.',
    inscriptionUrl:
      'https://www.fing.edu.uy/es/form/inscripcion-a-curso-cpap?source_entity_type=node&source_entity_id=4656',
    certificateAndAwards:
      'Al aprobar el curso el estudiante obtendrá un diploma emitido por la Facultad de Ingeniería de la Universidad de la República.',
    knowledgeRequired:
      'Conocimientos en desarrollo de Software y Sistemas de Información.',
    knowledgeNiceToHave:
      'Experiencia o conocimientos en procesos de desarrollo e ingeniería de software, así como en modelado con UML',
    evaluationMethodology: '',
    scholarshipsAndDiscounts:
      'Se pueden solicitar descuentos por el plan de referidos, grupos de estudiantes por empresa, socios de la CUTI, entre otros. [Más información](https://www.fing.edu.uy/index.php/es/cpap/becas_descuentos)',

    topics: [
      {
        title: 'Introducción',
        description:
          'Arquitectura de Software y su importancia. Niveles de arquitectura (p. ej. aplicación, sistema). Ejemplos actuales de arquitecturas de software. Rol del arquitecto de software en los distintos niveles.',
      },
      {
        title: 'Proceso de Definición de una Arquitectura',
        description:
          'Elementos que guían la definición de una arquitectura (p. ej. principios claves de arquitectura, requerimientos funcionales, atributos de calidad, escenarios). Decisiones arquitectónicas y su impacto. Evaluación de arquitectura y retroalimentación.',
      },
      {
        title: 'Documentación de una Arquitectura',
        description:
          'Importancia de la representación y documentación de una arquitectura. Enfoque basado en vistas para documentar los diferentes aspectos de la arquitectura. Documento de Arquitectura de Software (Software Architecture Document, SAD).',
      },
      {
        title: 'Estilos y Patrones de Arquitectura',
        description:
          'Principales estilos y patrones de arquitectura (p. ej. capas, arquitectura orientada a servicios, microservicios, pipes and filters, bus de servicios). Arquitecturas de referencia para sistemas de información actuales (p. ej. web, mobile, servicios, procesos).',
      },
      {
        title: 'Plataformas Tecnológicas y Arquitectura',
        description:
          'Plataformas de desarrollo (p. ej. Java EE). Plataformas de integración (p. ej. ESB, iPaaS). Plataformas de despliegue (p. ej. cloud, contenedores). Plataformas tecnológicas emergentes.',
      },
      {
        title: 'Evaluación de Arquitecturas',
        description: 'Mecanismos de evaluación de arquitecturas de software.',
      },
      {
        title:
          'Problemáticas y Tendencias actuales en Arquitectura de Software.',
      },
    ],
  },
];
