import React, { useState } from 'react';
import GeneralData from '../../generalData';

function Curso(props) {
  const [open, setOpen] = useState(false);

  const Data = props.data;

  function showMore() {
    setOpen(!open);
  }

  return (
    <div
      onClick={showMore}
      className="cursor-pointer w-full bg-primary-50 shadow-sm hover:shadow rounded-xl p-4 "
    >
      <h3
        className={`mb-1  text-lg font-bold ${GeneralData.style.layout.text.dark}`}
      >
        {Data.title}
      </h3>
      <blockquote
        className={`text-base ${GeneralData.style.layout.text.darkLight} space-y-3 cursor-pointer`}
      >
        <p className="whitespace-pre-line w-full text-sm">
          {open ? (
            Data.longDescription
          ) : (
            <span>
              {`${Data.longDescription.slice(0, 250)} `}{' '}
              <span
                className={`ml-2 ${GeneralData.style.layout.text.primaryLight}`}
              >
                ... Leer más{' '}
              </span>{' '}
            </span>
          )}
        </p>

        {Data.urlMoreInformation !== undefined &&
          Data.urlMoreInformation !== '' &&
          open && (
            <div
              className={`text-sm ${GeneralData.style.layout.text.primaryLight} cursor-pointer`}
            >
              <a href={Data.urlMoreInformation}>Información extra</a>
            </div>
          )}
        {Data.references.length > 0 && open && (
          <div>
            <div className="text-xs font-bold ">Referencias:</div>
            <ul className="space-y-2 list-disc pl-4">
              {Data.references.map(function (reference) {
                return <li className="text-xs ">{reference}</li>;
              })}
            </ul>
          </div>
        )}
      </blockquote>
    </div>
  );
}

export default Curso;
