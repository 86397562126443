import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './../layoutComponents/layout';
import Router from './../../router';
import InitPageHelper from '../helpers/PageInitHelper';
import CursoPageComponent from './cursos/CursoPageComponent';
import useCurso from '../composables/use-curso';

function Curso() {
  const { cursoByName } = useCurso();

  let name = useParams().name.toLowerCase().replaceAll('-', ' ');

  const curso = cursoByName(name);

  useEffect(() => {
    window.scrollTo(0, 0);
    InitPageHelper.PageInitHelper({
      title: curso.name,
      description: curso.shortDescription,
    });
  }, [curso.name]);

  return (
    <Layout
      customClasses="bg-gradient-to-b from-white to-primary-50  lg:py-8 p-3"
      showBreadcrumb={true}
      showBreadcrumbData={[
        { name: 'Enseñanza', route: Router.ensenanza },
        { name: 'Carreras', route: Router.carreras },
        { name: name.slice(0, 16) + '...', route: '' },
      ]}
    >
      <>
        <div className="container max-w-6xl mx-auto bg-white">
          <CursoPageComponent {...curso} />
        </div>
      </>
    </Layout>
  );
}

export default Curso;
