import carreras from './../../../data/carreras';
import useCurso from './use-curso';

const carreraByName = (name) => {
  return carreras.find(
    (carrera) => carrera.name.toLocaleLowerCase() == name.toLocaleLowerCase()
  );
};

export default function useCarrera() {
  const getDynamicTeacherTeamsNoResponsableNoCoordinator = (
    coursesIds,
    namesToAvoid = []
  ) => {
    const { getCourseTeachers, getCareerCoursesFromIds } = useCurso();

    const courses = getCareerCoursesFromIds(coursesIds);

    const teachersNames = [];
    teachersNames.push(...namesToAvoid);
    const teacherTeams = [];

    courses.forEach((course) => {
      const teachers = getCourseTeachers(course);

      const teachersNoResponsableNoCoordinator = teachers.filter(
        (teacher) => !teachersNames.includes(teacher.name)
      );

      teacherTeams.push(
        ...teachersNoResponsableNoCoordinator.map((teacher) => {
          return { name: teacher.name };
        })
      );
      teachersNames.push(
        ...teachersNoResponsableNoCoordinator.map((teacher) => teacher.name)
      );
    });

    return teacherTeams;
  };

  return {
    carreras,
    carreraByName,
    getDynamicTeacherTeamsNoResponsableNoCoordinator,
  };
}
