import validURL from './validUrl';
const router = {
  home: '/',
  contacto: '/contacto',
  investigacion: '/investigacion',
  integrantes: '/integrantes',
  ensenanza: '/ensenanza',
  propuestasDeTesis: '/propuestas-de-tesis',
  tutorias: '/tutorias',
  cursos: '/cursos',
  carreras: '/carreras',
  actividades: '/actividades',
  about: '/sobre-el-grupo-de-ingenieria-de-software',
  cursosDeGrado: '/cursos-de-grado',
  cursosDePostGrado: '/cursos-de-posgrado',
  propuestasDeTesisDeGrado: '/propuestas-de-tesis-de-grado',
  propuestasDeTesisDePostGrado: '/propuestas-de-tesis-de-posgrado',
  tutoriasDeGrado: '/tutorias-de-grado',
  tutoriasDePostGrado: '/tutorias-de-posgrado',
  integranteGet: function (name) {
    return `/integrantes/${name.trim().toLowerCase().replaceAll(' ', '-')}`;
  },
  integrante: '/integrantes/:name',
  charlaSeminarioGet: function (name) {
    return `/charla/${name.toLowerCase().trim().replace(validURL, '-')}`;
  },
  carreraGet: function (name) {
    return `/carreras/${name.toLowerCase().trim().replace(validURL, '-')}`;
  },
  cursoGet: function (name) {
    return `/cursos/${name.toLowerCase().trim().replace(validURL, '-')}`;
  },
  planGet: function (name) {
    return `/carreras/${name.toLowerCase().trim().replace(validURL, '-')}/plan`;
  },
  charlaSeminario: '/charla/:name',
  curso: '/cursos/:name',
  carrera: '/carreras/:name',
  plan: '/carreras/:name/plan',
  categoria_programa: '/programas/categoria/:name/:id',
};

export default router;
